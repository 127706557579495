import React from 'react';

export default function CustomQuestions(props) {
  const [questions, setQuestions] = React.useState(props.questions || []); 

  const handleAdd = () => {
    var arr = questions || [];
    setQuestions(arr.concat({ fi: "", sv: "", en: "", et: "", ru: "" }));
  }

  const handleRemove = (question) => {
    var arr = questions.concat([])
    var index = arr.indexOf(question);
    if (index > -1) {
      arr.splice(index, 1);
    }
    setQuestions(arr)
  }

  const handleUpdate = () => {
    setQuestions([...questions]);
  }

  return (
    <div>      
      <div >
        <div className="row">
          {questions.map((item, index) => (
            <Question
              index={index}
              key={index}
              question={item}
              handleRemove={handleRemove}
              update={handleUpdate}
            />
          ))}
        </div>
        <div className="row">
          <button type="button" className="btn" style={{ margin: "3px 3px 3px 0px" }} disabled={questions.length >= 5} onClick={() => handleAdd()}>{I18n.t('add_question')}</button>
        </div>
      </div>
    </div>
  );
}

function Question(props) {
  const handleText = (e, language) => {
    props.question[language] = e.target.value;
    props.update();
  }

  return (
    <div className="col-12" style={{ border: "solid 1px lightgray", padding: "10px 20px", marginBottom: "25px"}}>
      <button className="btn btn-secondary" style={{float: 'right'}} onClick={() => props.handleRemove(props.question)}>{I18n.t('T_remove')}</button>
      <h2>{I18n.t('custom_question') + " " + (props.index + 1)}</h2>
      {['fi', 'sv', 'en', 'et', 'ru'].map((language) => (
        <div key={language}>
          <p className="bold" style={{margin: 0}}>{I18n.t('T_' + language)}</p>
          <textarea
            id={'custom_question_' + props.index}
            rows="1"
            style={{marginBottom: "5px", width: "100%"}}
            value={props.question[language]}
            onChange={(e) => handleText(e, language)}
          />
           <input name={"questionnaire[custom_questions][" + props.index +"]["+language+"]"} type={"text"} value={props.question[language]} style={{ display: 'none' }} onChange={e => { }}/>
        </div>
      ))}
  </div>
  );
}