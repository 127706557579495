// Helper function that groups by given getter and sorts the values by given getter
export function groupByAndSort(list, groupByKeyGetter, sortByFunction, ignored) {
  const map = new Map();

  // Group
  list.forEach((item) => {
    const key = groupByKeyGetter(item);
    const collection = map.get(key);

    // Get item only if it doesn't appear in ignored items
    ignored.forEach(i => {
    if (("T_" + i) != item.tkey)  {
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    }
  });

  });

  // Sort values
  for (var [key, value] of map.entries()) {
    map.set(key, value.sort(sortByFunction));
  }

  return map;
}