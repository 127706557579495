import { Controller } from "stimulus";
import React from "react";
import ReactDOM from "react-dom";

import EmployeeImport from "@views/employee/import";
import EmployeeForm from "@views/employee/employee_form";
import ParticipantSelection from "@views/participant/selection";
import ParticipantResolve from "@views/participant/resolve";
import QuestionnaireEventList from "@views/questionnaire_event/list";
import QuestionnaireEditor from "@views/questionnaire/questionnaire_editor";
import Reports from "@views/report/reports";

import Table from "@components/table";
import InlineTextInput from "@components/inline_text_input";
import Dialog from "@components/dialog";
import LoadingIndicator from "@components/loading_indicator";
import DropdownMenu from "@components/dropdown_menu";
import Select from "@components/select";
import SideBar from "@components/side_bar";
import ProgressBar from "@components/progress_bar";
import Questionnaire from "@components/questionnaire";
import TagSelector from "@components/tag_selector";
import EmployeeSearch from "@components/employee_search";
import CustomQuestions from "@components/custom_questions";
import AnswerFilter from "@components/answer_filter";
import ContentToTextBtn from "@components/content_to_text";
import DoughnutGraph from "@components/doughnut_graph";
import LanguageSelector from "@components/language_selector";

var components = {
  // Views
  EmployeeImport,
  EmployeeForm,
  ParticipantSelection,
  ParticipantResolve,
  QuestionnaireEventList,

  // Components
  Table,
  Dialog,
  InlineTextInput,
  LoadingIndicator,
  Questionnaire,
  DropdownMenu,
  SideBar,
  Select,
  ProgressBar,
  TagSelector,
  Reports,
  EmployeeSearch,
  CustomQuestions,
  AnswerFilter,
  ContentToTextBtn,
  QuestionnaireEditor,
  DoughnutGraph,
  LanguageSelector
};

export default class extends Controller {
  connect() {
    const Component = components[this.data.get("component")];
    let props = this.data.get("props") || {};
    if (typeof props === "string") {
      props = JSON.parse(props);
    }

    ReactDOM.render(<Component {...props} />, this.element);

    document.addEventListener("turbolinks:before-cache", this.unmountComponent);
  }

  disconnect() {
    document.removeEventListener(
      "turbolinks:before-cache",
      this.unmountComponent
    );
  }

  unmountComponent = () => {
    ReactDOM.unmountComponentAtNode(this.element);
  };
}
