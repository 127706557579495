import React, {useEffect, Fragment} from 'react';
import Dialog from '@components/dialog';
import MenuItem from '@material-ui/core/MenuItem';

export default function ActionButton(props){

  const [open, setOpen] = React.useState(false);
  
  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(!open)
  } 

  const closeDropdowns = (e) => {
    setOpen(false);
  }

  const clicked = (e, item) => {
    if (!item.confirm || confirm(I18n.t(item.confirm))){
      window.location.href = item.link;
    }
    e.stopPropagation();
  }
  
  useEffect(() => {
    if (open){
      document.addEventListener('click', closeDropdowns);
    
      return () => {
        document.removeEventListener('click', closeDropdowns)
      }
    }
  }, [open]);

  return(
    <div className="dropdown action-button-wrapper">
      {props.actions.length > 0 ?
        <Fragment>
          <span className="action-button" onClick={handleClick}>
            <span className="dot" />
            <span className="dot" />
            <span className="dot" />
          </span>
          <div className={"dropdown-menu " + (open ? 'show' : '')} style={{ right: 0, left: 'unset', position: 'absolute' }}>
            {props.actions.map((item, idx) => item.dialog ?
              <Dialog
                key={idx}
                contentUrl={item.link}
                dialogBtnClass="dropdown-item"
                dialogBtnText={item.label}
                dialogTitle={item.label}
              />
              :
              <MenuItem key={idx} onClick={(e) => clicked(e, item)}>{item.label}</MenuItem>
            )}
          </div>
        </Fragment>
        :
        null
      }
      </div>
  )
}
//<a className="dropdown-item" key={idx} onclick="return confirm('Are you sure?')" href={item.link}>{item.label}</a>