import React from "react";
import { Grid } from "@material-ui/core";

import QuestionnaireValueShortText from "../form_fields/questionnaire_value_short_text";
import QuestionnaireValueLongText from "../form_fields/questionnaire_value_long_text";
import QuestionnaireTextBlock from "../form_fields/questionnaire_text_block";
import QuestionnaireValueSelect from "../form_fields/questionnaire_value_select";
import QuestionnaireValueRadio from "../form_fields/questionnaire_value_radio";
import QuestionnaireValueRadioFloat from "../form_fields/questionnaire_value_radio_float";
import QuestionnaireValueHorizontalRadio from "../form_fields/questionnaire_value_horizontal_radio";
import QuestionnaireValueDate from "../form_fields/questionnaire_value_date";
import QuestionnaireCategoryTitle from "../form_fields/questionnaire_category_title";
import QuestionnaireValueInteger from "../form_fields/questionnaire_value_integer";
import QuestionnaireConditionChanger from "../form_fields/questionnaire_condition_changer";

const FORM_FIELDS = {
  QuestionnaireValueShortText,
  QuestionnaireValueLongText,
  QuestionnaireValueSelect,
  QuestionnaireValueRadio,
  QuestionnaireValueRadioFloat,
  QuestionnaireValueHorizontalRadio,
  QuestionnaireValueDate,
  QuestionnaireCategoryTitle,
  QuestionnaireTextBlock,
  QuestionnaireValueInteger,
  QuestionnaireConditionChanger,
};

const PROTECTED_FIELDS = [
  "person_first_name",
  "person_last_name",
  "person_email",
  "person_birthdate",
  "person_gender",
];

export default function QuestionnaireTemplatePage(props) {
  const { questions, answers, saveAnswer, initialConditions, language } = props;
  I18n.locale = language

  const [activeConditions, setActiveConditions] = React.useState(
    initialConditions
  );
  const updateActiveCondition = (condition, value) => {
    let newActiveCondtions = { ...activeConditions };
    newActiveCondtions[condition] = value;
    setActiveConditions(newActiveCondtions);
  };

  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      style={{ paddingLeft: 40, paddingRight: 40 }}
    >
      {questions.map((question, idx) => {
        const Field = FORM_FIELDS[question.type];
        return (
          <>
            {
              // NOTE: Temporary solution to check for dependancy values
              // TODO: Improve this if more detailed dependancies are required
            }
            {!question.dependancy || (() => {
                let allDependancyMet = false;
                let dependancies = question.dependancy.split('&&')
                let dependanciesValues = dependancies.map((dependancy => {
                  const parts = dependancy.split('!=');
                  if (parts.length > 1) {
                    return parts[1].toString();
                  }
                  return -1
                }))
                dependancies.forEach((dependancy, idx) => {
                  const parts = dependancy.split('!=');
                  if (parts.length > 1) {
                    const dependencyKey = parts[0];
                    if (idx === dependancies.length-1 && answers[dependencyKey] && !dependanciesValues.includes(answers[dependencyKey].toString())) {
                      allDependancyMet = true;
                      return;
                    }
                  }
                });
                return allDependancyMet;
              })()
              ? (
                <Field
                  disabled={
                    props.disabled ||
                    (props.strongAuthentication &&
                      PROTECTED_FIELDS.includes(question.str_id))
                  }
                  answer={answers[question.str_id]}
                  saveAnswer={saveAnswer}
                  key={`question-${idx}`}
                  activeConditions={activeConditions}
                  updateActiveCondition={updateActiveCondition}
                  language={language}
                  {...question}
                />
              ) : (
                <></>
              )}
          </>
        );
      })}

      {props.children}
    </Grid>
  );
}
