import React, { useEffect } from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import {
  FormControl,
  FormGroup,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  OutlinedInput,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@components/divider";

import "moment/locale/fi";

const useStyles = makeStyles((theme) => ({
  normalText: {
    transform: "translate(0, 0) scale(1)",
  },
}));

const FIELDS = {
  scheduled: "scheduled",
  scheduled_at: "scheduled_at",
  email_title: "email_title",
  email_text: "email_text",
  expiration_at: "expiration_at",
  low: "low",
  medium: "medium",
  high: "high",
  undefined: "undefined",
};

const DEFAULTS_BY_TYPE = {
  invite: {
    [FIELDS.scheduled]: true,
    [FIELDS.scheduled_at]: moment(),
    [FIELDS.email_title]: I18n.t(
      "T_new_questionnaire_announcement_default_title"
    ),
    [FIELDS.email_text]: I18n.t(
      "T_new_questionnaire_announcement_default_text"
    ),
    [FIELDS.expiration_at]: moment().add(14, "days"),
  },
  reminder: {
    [FIELDS.scheduled]: true,
    [FIELDS.scheduled_at]: moment(),
    [FIELDS.email_title]: I18n.t("T_new_questionnaire_reminder_default_title"),
    [FIELDS.email_text]: I18n.t("T_new_questionnaire_reminder_default_text"),
    [FIELDS.expiration_at]: moment().add(14, "days"),
  },
  freeze: {
    [FIELDS.scheduled]: true,
    [FIELDS.scheduled_at]: moment(),
  },
  feedback: {
    [FIELDS.scheduled]: true,
    [FIELDS.scheduled_at]: moment(),
    [FIELDS.email_title]: I18n.t("new_questionnaire_feedback_default_title"),
    [FIELDS.email_text]: I18n.t("new_questionnaire_feedback_default_text"),
    [FIELDS.expiration_at]: moment().add(14, "days"),
    [FIELDS.low]: true,
    [FIELDS.medium]: true,
    [FIELDS.high]: true,
    [FIELDS.undefined]: false,
  },
};

export default function QuestionnaireEventForm(props) {
  const [first, setFirst] = React.useState(true);
  const classes = useStyles();

  // Defaults if none provided.
  const values = { ...DEFAULTS_BY_TYPE[props.type], ...props.formData };

  const handleChange = (key, value) => {
    const newValues = { ...values, ...{ [key]: value } };
    values[FIELDS[key]] = value;
    props.setFormData(newValues);
  };

  useEffect(() => {
    if (first) {
      moment.locale("fi");
      if (props.type != "freeze") {
        var lastMessage = props.lastMessages && props.lastMessages[props.type];
        if (
          lastMessage == undefined &&
          props.lastMessages &&
          props.type == "invite"
        ) {
          lastMessage = props.lastMessages["reminder"];
        }
        if (
          lastMessage == undefined &&
          props.lastMessages &&
          props.type == "reminder"
        ) {
          lastMessage = props.lastMessages["invite"];
        }

        if (lastMessage) {
          if (lastMessage.body.email_title) {
            handleChange(FIELDS.email_title, lastMessage.body.email_title);
            DEFAULTS_BY_TYPE[props.type][FIELDS.email_title] =
              lastMessage.body.email_title;
          }
          if (lastMessage.body.email_text) {
            handleChange(FIELDS.email_text, lastMessage.body.email_text);
            DEFAULTS_BY_TYPE[props.type][FIELDS.email_text] =
              lastMessage.body.email_text;
          }
        } else {
          handleChange(FIELDS.email_title, values.email_title);
          handleChange(FIELDS.email_text, values.email_text);
          handleChange(FIELDS.expiration_at, values.expiration_at);
        }
      }

      if (props.type == "feedback") {
        if (props.target_groups) {
          DEFAULTS_BY_TYPE[props.type][FIELDS.low] = props.target_groups.low;
          DEFAULTS_BY_TYPE[props.type][FIELDS.medium] =
            props.target_groups.medium;
          DEFAULTS_BY_TYPE[props.type][FIELDS.high] = props.target_groups.high;
          DEFAULTS_BY_TYPE[props.type][FIELDS.undefined] =
            props.target_groups.undefined;
        }
        handleChange(FIELDS.low, values.low);
        handleChange(FIELDS.medium, values.medium);
        handleChange(FIELDS.high, values.high);
        handleChange(FIELDS.undefined, values.undefined);
      }
      if (props.scheduled)
        DEFAULTS_BY_TYPE[props.type][FIELDS.scheduled] = props.scheduled === 1;
      if (props.scheduled_at)
        DEFAULTS_BY_TYPE[props.type][FIELDS.scheduled_at] = props.scheduled_at;
      handleChange(
        FIELDS.scheduled,
        DEFAULTS_BY_TYPE[props.type][FIELDS.scheduled]
      );
      handleChange(
        FIELDS.scheduled_at,
        DEFAULTS_BY_TYPE[props.type][FIELDS.scheduled_at]
      );
      setFirst(false);
    }
  });

  return (
    <div>
      <FormControl component="fieldset">
        <FormLabel>{I18n.t("scheduling")}</FormLabel>
        <RadioGroup
          value={values[FIELDS.scheduled]}
          aria-label={FIELDS.scheduled}
          name={FIELDS.scheduled}
          onChange={(e) => {
            handleChange(FIELDS.scheduled, event.target.value == "true");
          }}
        >
          <FormControlLabel
            key={`${FIELDS.scheduled}_option_2`}
            value={false}
            control={<Radio color="primary" />}
            label={I18n.t("now")}
          />
          <FormControlLabel
            key={`${FIELDS.scheduled}_option_1`}
            value={true}
            control={<Radio color="primary" />}
            label={I18n.t("at_time")}
          />
        </RadioGroup>
      </FormControl>

      {values["scheduled"] && (
        <div>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDateTimePicker
              id={FIELDS.scheduled_at}
              inputVariant="outlined"
              format="DD.MM.YYYY HH:mm"
              className={classes.textField}
              margin="dense"
              label=""
              ampm={false}
              value={values[FIELDS.scheduled_at]}
              disablePast={true}
              onChange={(date) => {
                handleChange(FIELDS.scheduled_at, date);
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
      )}

      {props.type == "feedback" && (
        <FormGroup>
          <div className="bold">{I18n.t("recipients")}</div>
          {["low", "medium", "high", "undefined"].map((option) => (
            <FormControlLabel
              key={`form_${option}`}
              style={{ margin: 0, height: "24px" }}
              control={
                <Checkbox
                  name={`${option}_checkbox`}
                  color="primary"
                  checked={values[FIELDS[option]]}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                  onChange={(e) => {
                    handleChange(FIELDS[option], !values[FIELDS[option]]);
                  }}
                />
              }
              label={I18n.t(`${option}_risk_group`)}
            />
          ))}
        </FormGroup>
      )}

      {DEFAULTS_BY_TYPE[props.type][FIELDS.email_title] && (
        <div>
          <Divider />

          <FormLabel className="mt-2">{I18n.t("email_title")}</FormLabel>
          <OutlinedInput
            id={FIELDS.email_title}
            className={classes.textField}
            value={values[FIELDS.email_title]}
            margin="dense"
            variant="outlined"
            onChange={(e) => {
              handleChange(FIELDS.email_title, e.target.value);
            }}
            fullWidth={true}
          />

          <FormLabel className="mt-3">{I18n.t("email_text")}</FormLabel>
          <OutlinedInput
            id={FIELDS.email_text}
            className={classes.textField}
            value={values[FIELDS.email_text]}
            margin="dense"
            variant="outlined"
            onChange={(e) => {
              handleChange(FIELDS.email_text, e.target.value);
            }}
            multiline={true}
            fullWidth={true}
            rows={3}
            rowsMax={24}
          />
        </div>
      )}
    </div>
  );
}
