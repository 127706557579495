import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

export default function AnswerFilter(props) {
  const [show, setShow] = React.useState(false);
  const [showResults, setShowResults] = React.useState(false);
  const [questions] = React.useState(props.questions);
  const [openQuestions, setOpenQuestions] = React.useState([]);

  const [filter, setFilter] = React.useState({});
  const [groupFilter, setGroupFilter] = React.useState({});
  const [groupValues, setGroupValues] = React.useState({});
  const [currentFilters, setCurrentFilters] = React.useState();

  const handleSubmit = () => {
    var urlParts = window.location.href.split('?');
    var path = urlParts[0];
    var questionnaireIdsMatch = (urlParts[1] || '').match(/questionnaire_ids=([^&]*)/);

    if (Array.isArray(questionnaireIdsMatch) && questionnaireIdsMatch.length >= 2) {
      path += `&questionnaire_ids=${questionnaireIdsMatch[1]}`
    }
    Object.keys(filter).forEach((key) => {
      path += "&question[" + key + "]=" + filter[key];
    });
    Object.keys(groupFilter).forEach((key) => {
      path += "&group[" + key + "]=" + groupFilter[key];
    });
    Object.keys(groupValues).forEach((key) => {
      path += "&range[" + key + "]=" + groupValues[key];
    });
    Turbolinks.visit(path.replace("&", "?"));
  };

  const toggleShow = (question) => {
    if (openQuestions.includes(question)) {
      setOpenQuestions(openQuestions.filter((item) => item != question));
    } else {
      setOpenQuestions([...openQuestions, question]);
    }
  };

  const toggleFilter = (question, value) => {
    var filters = JSON.parse(JSON.stringify(filter));
    if (!filter[question]) {
      filters[question] = [value];
    } else {
      if (filters[question].includes(value)) {
        filters[question] = filters[question].filter((item) => item != value);
        if (filters[question].length == 0) {
          delete filters[question];
        }
      } else {
        filters[question].push(value);
      }
    }
    setFilter(filters);
  };

  const toggleGroupFilter = (group, value) => {
    var filters = JSON.parse(JSON.stringify(groupFilter));
    if (!filters[group]) {
      filters[group] = [value];
    } else {
      if (filters[group].includes(value)) {
        filters[group] = filters[group].filter((item) => item != value);
        if (filters[group].length == 0) {
          delete filters[group];
        }
      } else {
        filters[group].push(value);
      }
    }
    setGroupFilter(filters);
  };

  const handleGroupValue = (group, value, isMin) => {
    var filters = JSON.parse(JSON.stringify(groupValues));
    if (!filters[group]) filters[group] = [0, 100];
    filters[group][isMin ? 0 : 1] = value;
    setGroupValues(filters);
  };

  const translatedName = (tkey) => {
    return (
      I18n.t(tkey).match(/[A-ZÄÖ][ ,\/\-\(\)a-zA-ZäÄöÖ\s:\d]+[.!?]/) ||
      I18n.t(tkey).match(/([*])[a-zA-Z.)]+(?=[*])/) ||
      I18n.t(tkey)
    );
  };

  useEffect(() => {
    if (currentFilters == undefined) {
      var raw = window.location.search.substring(1).split("&");
      if (raw.length == 1 && raw[0] == "") {
        return;
      }
      var values = {};
      raw.forEach((part) => {
        var v = part.split("=");
        values[v[0]] = v[1].split(",").map((s) => parseInt(s));
      });
      var valueGroups = {};
      setCurrentFilters(values);
    }
  }, []);

  return (
    <div style={{ marginBottom: "10px" }}>
      <button
        className="btn btn-secondary"
        style={{ float: "none", marginBottom: "5px" }}
        onClick={(e) => setShowResults(!showResults)}
      >
        {showResults ? "-" : "+"}
        {I18n.t("filter_by_results")}
      </button>
      <div className="row" style={{ margin: "0 10px 5px 10px" }}>
        {showResults &&
          props.resultGroups.map((group) => (
            <div className="col-3-lg">
              <div className="bold" style={{ paddingLeft: "12px" }}>
                {I18n.t(group)}
              </div>
              <FormGroup>
                {["low", "medium", "high"].map((tier) => (
                  <FormControlLabel
                    key={`${group}_${tier}`}
                    style={{ margin: 0, height: "16px" }}
                    control={
                      <Checkbox
                        style={{ width: "15px", height: "15px" }}
                        name={tier}
                        color="primary"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                        checked={
                          filter[group] && groupFilter[group].includes(tier)
                        }
                        onChange={(e) => toggleGroupFilter(group, tier)}
                      />
                    }
                    label={I18n.t(tier + "_risk_count")}
                  />
                ))}
                <TextField
                  type="number"
                  style={{ paddingLeft: "10px", width: "80px" }}
                  value={groupValues[group] ? groupValues[group][0] : 0}
                  onChange={(e) =>
                    handleGroupValue(group, e.target.value, true)
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Min</InputAdornment>
                    ),
                  }}
                />
                <TextField
                  type="number"
                  style={{ paddingLeft: "10px", width: "80px" }}
                  value={groupValues[group] ? groupValues[group][1] : 0}
                  onChange={(e) =>
                    handleGroupValue(group, e.target.value, false)
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Max</InputAdornment>
                    ),
                  }}
                />
              </FormGroup>
            </div>
          ))}
      </div>
      <button
        className="btn btn-secondary"
        style={{ float: "none", marginBottom: "5px" }}
        onClick={(e) => setShow(!show)}
      >
        {show ? "-" : "+"}
        {I18n.t("filter_by_answers")}
      </button>
      {show && (
        <div>
          {questions
            .sort((a, b) => a.sort_order - b.sort_order)
            .map((question) => (
              <div key={"container_" + question.name}>
                <button
                  className="bold"
                  key={"title_" + question.name}
                  style={{
                    display: "inline",
                    backgroundColor: "transparent",
                    border: "none",
                  }}
                  onClick={(e) => toggleShow(question.name)}
                >
                  {(openQuestions.includes(question.name) ? "-" : "+") +
                    " " +
                    translatedName(question.name)}
                </button>
                <div>
                  {openQuestions.includes(question.name) && (
                    <FormGroup style={{ marginLeft: "25px" }}>
                      {question.options.map((option) => (
                        <FormControlLabel
                          style={{ margin: 0, height: "16px" }}
                          control={
                            <Checkbox
                              style={{ width: "15px", height: "15px" }}
                              name={option.name}
                              color="primary"
                              inputProps={{
                                "aria-label": "secondary checkbox",
                              }}
                              checked={
                                filter[question] &&
                                filter[question].includes(option.value)
                              }
                              onChange={(e) =>
                                toggleFilter(question.key, option.value)
                              }
                            />
                          }
                          label={I18n.t(option.name)}
                        />
                      ))}
                    </FormGroup>
                  )}
                </div>
              </div>
            ))}
        </div>
      )}
      {(show || showResults) && (
        <button
          className="btn"
          style={{ float: "none" }}
          onClick={(e) => handleSubmit()}
        >
          {I18n.t("filter")}
        </button>
      )}
    </div>
  );
}
