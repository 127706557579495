import React from 'react';
import { defaultPostHeaders } from '@helpers/index';

export default function EmployeeSearch(props) {
  const [search, setSearch] = React.useState("");
  const [employees, setEmployees] = React.useState([]);
  const [itemClicked, setItemClicked] = React.useState(false);
  const [lastTime, setLastTime] = React.useState()
  const [focus, setFocus] = React.useState(false);

  const getResults = (str) => {
    fetch(props.routes.search, {
      body: JSON.stringify({ search: str, time: Date.now() }),
      method: 'post',
      headers: defaultPostHeaders(),
    }).then(response => response.json()
      .then((json) => {
        if (focus && lastTime == null || json.time > lastTime) {
          setEmployees(json.employees)
          setLastTime(Date.now());
        }
      })
    )
    .catch(error => {
      console.log(error)
    })
  }

  const handleSearch = (e) => {
    setSearch(e.target.value);
    if (e.target.value.length > 1) {
      getResults(e.target.value);
    }
    else {
      setEmployees([]);
    }
  }

  const handleClick = (e, employee) => {
    setItemClicked(true);
    e.stopPropagation();
    e.preventDefault();
    var route = props.routes.employee
    route = route.replace('__id__', employee.id)
    route = route.replace('__customer__', employee.customer_id)
    route = route.replace('__company__', employee.company_id)
    Turbolinks.visit(route)    
  }

  const handleClose = () => {
    setFocus(false);
    setTimeout(() => {
      if (!itemClicked) {
        setEmployees([]);
        setSearch("");
      }
    }, 150);
  }

  return (
    <div style={{ float: 'left' }}>
      <div style={{display: 'flex', whiteSpace: "nowrap"}}>
        <div className="bold" style={{marginRight: 10, padding: 3}}>{I18n.t("employee_search")}</div>
        <input
          style={{ width: "100%", width: "250px" }}
          value={search || ""}
          onChange={(e) => handleSearch(e)}
          placeholder={I18n.t("employee_search_placeholder")}
          onFocus={e => setFocus(true)}
          onBlur={e => handleClose()}
        />
        <i className="material-icons">search</i>
      </div>
      <ul style={{ listStyleType: "none", padding: "0px", width: "100%", position: 'relative'}}>
        <div className="suggestion-container" style={{ border: "solid 1px lightgray", backgroundColor: "white", overflow: 'hidden', maxHeight: "400px", overflowY: 'auto', display: employees.length > 0 ? 'inherit' : 'none'}}>
          {employees.map((employee, index) => (
            <button
              className="suggestion"
              key={"emp_" + index}
              style={{textAlign: 'left', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: "nowrap"}}
              onClick={(e) => handleClick(e, employee)}
            >
              {employee.last_name + " " + employee.first_name + (employee.ssid_number ? " (" + employee.ssid_number + ")" : "") + (employee.customer_name ? " - " + employee.customer_name : "")}
            </button>
          ))
          }
        </div>
      </ul>
    </div>
  )
}