import React, { useEffect } from "react";

import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import FormLabel from "@material-ui/core/FormLabel";

import Table from "@components/table";
import Filter from "@views/report/filter";
import BarDialog from "@views/report/bar_dialog";
import TagSelector from "@components/tag_selector";

var questionnaireIds = []

export default function Reports(props) {
  
  const filterQuestionnaires = (template, tagFilters, customerTagFilters) => {
    var filtered = props.table_data.filter(function (el) {
      return (
        template.questionnaire_type == "" ||
        el.calculations.includes(template.questionnaire_type)
      );
    });
    if (tagFilters && tagFilters.length > 0)
      filtered = filtered.filter((data) =>
        tagFilters.every((v) => data.tags.includes(v))
      );
    if (customerTagFilters && customerTagFilters.length > 0)
      filtered = filtered.filter((data) =>
        customerTagFilters.every((v) => data.customer_tags.includes(v))
      );
    //setQuestionnaires(filtered);
    return filtered
  };
  
  console.log("Reports", new Date().toDateString())
  const [template, setTemplate] = React.useState(props.template_types[0]);
  const [questionnaires, setQuestionnaires] = React.useState(filterQuestionnaires(props.template_types[0], [], []));
  const [customerTags] = React.useState(props.customer_tags);

  const [error, setError] = React.useState();
  const [first, setFirst] = React.useState(true);
  //const [questionnaireIds, setQuestionnaireIds] = React.useState([]);
  const [barQuestionnaires, setBarQuestionnaires] = React.useState([])
  const [tagFilters, setTagFilters] = React.useState([]);
  const [customerTagFilters, setCustomerTagFilters] = React.useState([]);

  const [comparisonType, setComparisonType] = React.useState('filters')
  const [employeeField, setEmployeeField] = React.useState(props.fields.length > 0 ? props.fields[0] : '')
  const [filterList, setFilterList] = React.useState(props.filter_data.filter_list);
  const [selectedFilters, setSelectedFilters] = React.useState([]);
  const [filters, setFilters] = React.useState([]);
  const [showFilterList, setShowFilterList] = React.useState(false);

  const [options, setOptions] = React.useState({ show_total: false, show_average: false, show_empty: false, horizontal: false });

  const handleTemplateChange = (item) => {
    if (item != template) {
      setTemplate(item);
      setComparisonType(item.comparison ? 'filters' : '')
      var filtered = filterQuestionnaires(item, tagFilters, customerTagFilters);
      setQuestionnaires(filtered);
      setFilters([]);
      setShowFilterList(false);
      setSelectedFilters([]);
    }
  };

  const handleTags = (tags) => {
    setTagFilters(tags);
    var filtered = filterQuestionnaires(template, tags, customerTagFilters);
    setQuestionnaires(filtered);
  };

  const handleCustomerTags = (tags) => {
    setCustomerTagFilters(tags);
    var filtered = filterQuestionnaires(template, tagFilters, tags);
    setQuestionnaires(filtered);
  };

  const addSymbol = (params) => {
    return params.length > 0 ? '&' : '?'
  }

  const intsToRanges = (ints) => {
    let last = -Infinity;
    let start = null;
    const result = [];
    const processLast = (i) => {
      if (i > last+1) {
        if (start !== null) {
          result.push(last > start ? `${start}-${last}` : last);
        }
        start = i;
      }
      last = i;
    };
    ints.sort((a, b) => (a - b)).forEach(processLast);
    processLast(Infinity);
    return result;
  }

  const handleReport = () => {
    var params = ""
    //params += `${addSymbol(params)}questionnaire_ids=${questionnaireIds.join(',')}`
    params += `${addSymbol(params)}questionnaire_ids=${intsToRanges(questionnaireIds).join(',')}`
    if (comparisonType === 'employee_fields') {
      params += `${addSymbol(params)}field=${employeeField}`
    }
    else if (comparisonType === 'filters' && filters.length > 0) {
      params += `${addSymbol(params)}bars=${filters.join(',')}`;
    }
    if (selectedFilters.length > 0) {
      params += `${addSymbol(params)}filters=${selectedFilters.join(',')}`;
    }
    if (template.averages) {
      if (options.show_average)
        params += `${addSymbol(params)}average=true`
      if (options.show_total)
        params += `${addSymbol(params)}total=true`
      if (options.show_empty)
        params += `${addSymbol(params)}show_empty=true`
      if (options.horizontal)
        params += `${addSymbol(params)}horizontal=true`
    }
    var data = {}
    questionnaireIds.forEach(q => {
      var str = q.toString();
      var target = data;
      while (str.length > 1) {
        var key = str[0] + ("0" * (str.length - 1));
        console.log(str, key, "0"*5);
        if (!target[key]) {
          target[key] = {};
        }
        if (str.length == 2) {
          target[str[0]] = str[1];
          str = "";
        }
        else {
          target = target[key];
          str = str.substring(1);
        }
      }
    });

    Turbolinks.visit(props.path.replace("_type_", template.name) + params);
  };

  const setSelected = (rows) => {
    var ids = rows.map((row) => row.original.id);
    var error = undefined;
    if (rows.length > 0) {
      var type = rows[0].original;
      rows.map((row) => {
        // Questionnaires that dont use same result calculations cannot be compared reliably
        if (type.calculations.sort().join(',') !== row.original.calculations.sort().join(',')) {
          error = "mixed_templates";
        }
      });
      if (!error) {
        if (rows.length < template.min_questionnaires) {
          error = "not_enough_questionnaires";
        } else if (rows.length > template.max_questionnaires) {
          error = "too_many_questionnaires";
        }
      }
    }
    setError(error);
    if (!arraysAreEqual(ids, questionnaireIds)) {
      //setQuestionnaireIds(ids);
      questionnaireIds = ids;
      setBarQuestionnaires(rows.map((row) => row.original))
      var selectedRemoved = false
      filters.forEach(f => {
        if (!selectedRemoved && f[0] == 'q') {
          selectedRemoved = !ids.includes(parseInt(f.substring(1)));
        }
      })
      if (selectedRemoved) {
        setFilters([]);
      }
    }
  };

  const updateFilters = (filters) => {
    setFilterList(JSON.parse(JSON.stringify(filters)));
  }

  const handleFilterSelection = (rows) => {
    var ids = rows.map((row) => row.original.id);
    if (!arraysAreEqual(ids, selectedFilters)) {
      setSelectedFilters(ids);
    }
  }

  const handleBarOrder = (moveLeft, bar_id) => {
    var index = filters.indexOf(bar_id);
    var temp = filters[index];
    var target_index = index + (moveLeft ? -1 : 1);
    if (target_index < 0 || target_index >= filters.length) {
      return
    }
    var arr = filters.slice();
    arr[index] = arr[target_index]
    arr[target_index] = temp
    setFilters(arr);
  }

  const handleOptions = (field, value) => {
    setOptions({ ...options, [field]: value });
  }

  const handleBarSubmit = (newBars) => {
    var difference = newBars.filter(x => !filters.includes(x))
    var arr = filters.concat(difference);
    setFilters(arr);
  }

  const toggleFilterList = () => {
    setSelectedFilters([]);
    setShowFilterList(!showFilterList);
  }

  const arraysAreEqual = (arr1, arr2) => {
    var isEqual = arr1.length == arr2.length
    if (isEqual) {
      for (var i = 0; i < arr1.length; i++) {
        isEqual = arr1[i] == arr2[i];
        if (!isEqual) {
          break;
        }
      }
    }
    return isEqual
  }

  return (
    <div
      style={{ background: "white", padding: "10px", paddingBottom: "50px" }}
    >
      <h1>{I18n.t("reports")}</h1>
      <div>
        {props.show_filters && <Filter handleSave={updateFilters} filterData={props.filter_data} submitPath={props.filter_submit_path} />}
        <div style={{ display: "inline" }}>
          <h2 style={{ display: "inline" }}>
            {"1. " + I18n.t("select_report_type")}
          </h2>
          <div style={{ marginTop: 10 }}>
            {props.template_types.map((item) => (
              <button
                className={"sub-tab " + (item == template ? "selected" : "")}
                key={item.name}
                value={item}
                onClick={() => handleTemplateChange(item)}
              >
                {I18n.t(item.name)}
              </button>
            ))}
          </div>
        </div>

        <h2 style={{ marginTop: "30px" }}>{"2. " + I18n.t("select_questionnaires")}</h2>
        {customerTags && (
          <>
            <div style={{ display: "inline" }}>{I18n.t("customer_tags")}</div>
            <TagSelector
              options={customerTags}
              tags={[]}
              allowInput={false}
              setTags={handleCustomerTags}
              hintText={I18n.t("filter_by_customer_tags")}
            />
          </>
        )}
        <div style={{ display: "inline" }}>{I18n.t("questionnaire_tags")}</div>
        <TagSelector
          options={props.tags || []}
          tags={[]}
          allowInput={false}
          setTags={handleTags}
          hintText={I18n.t("filter_by_questionnaire_tags")}
        />
      </div>
      <Table
        columnDefinitions={props.column_definitions}
        rawData={questionnaires}
        useSelect={true}
        useGlobalSearch={questionnaires.length > 10}
        setSelected={setSelected}
        selectAll={template.comparison ? true : false}
        skipColumDefs={true}
      />

      {
        <div style={{ marginBottom: (template.comparison && !showFilterList ? "30px" : 0) }}>

          <h2 style={{ marginTop: "0px" }}>{"3. " + I18n.t("select_questionnaire_filters")}</h2>
          <p style={{ marginTop: "0px" }}>{I18n.t("report_filter_info")}</p>
          {template.comparison &&
            <button onClick={() => toggleFilterList()} className={"btn btn-secondary"} style={{ float: 'none' }}>
              {I18n.t(showFilterList ? "hide_filters" : "show_filters")}
            </button>
          }
          {(!template.comparison || showFilterList) &&
            < FilterList
              filters={filterList}
              handleSelection={handleFilterSelection}
            />
          }
        </div>
      }

      {template.comparison &&
        <div>
          <div>
            <h2 style={{ marginTop: "0px" }}>{"4. " + I18n.t("select_filter_bars")}</h2>
            <RadioGroup
              aria-label="comparison type"
              style={{ display: 'inline' }}
              value={comparisonType}
              onChange={(e) => setComparisonType(e.target.value)}
            >
              <FormControlLabel value="filters" control={<Radio color="primary" />} label={I18n.t('custom_bars')} />
              <FormControlLabel value="questionnaire" control={<Radio color="primary" />} label={I18n.t('all_selected_questionnaires')} />
              <FormControlLabel value="employee_fields" control={<Radio color="primary" />} label={I18n.t('employee_fields')} />
            </RadioGroup>
            <p>{I18n.t(`comparison_description_${comparisonType}`)}</p>
          </div>

          {comparisonType === 'filters' &&
            <>
              <div style={{ display: 'flex', marginBottom: '20px' }}>
                <BarDialog
                  handleSave={updateFilters}
                  filters={filterList}
                  questionnaires={barQuestionnaires}
                  questionnaireColumns={props.column_definitions}
                  handleSubmit={handleBarSubmit}
                />
                {filters.length > 0 &&
                  <button
                    className={"btn btn-secondary"}
                    onClick={() => setFilters([])}
                    style={{ float: 'none' }}
                  >
                    {I18n.t('remove_all')}
                  </button>
                }
              </div>
              <FilterBarList
                filters={filterList}
                handleBarOrder={handleBarOrder}
                selectedFilters={filters}
                questionnaires={barQuestionnaires}
              />
            </>
          }

          {comparisonType === 'employee_fields' &&
            <>
              {props.fields.length > 0 ?
                <>
                  <Select value={employeeField} onChange={(e) => setEmployeeField(e.target.value)} style={{ marginBottom: "15px" }}>
                    {props.fields.map(f => (<MenuItem key={`emp_field_${f} `} value={f}>{f}</MenuItem>))}
                  </Select>

                </>
                : <p>{I18n.t('no_custom_fields')}</p>
              }
            </>
          }

          <div style={{ color: 'black' }}>
            <h2 style={{ display: "inline" }}>
              {"5. " + I18n.t("report_options")}
            </h2>

            <FormLabel className="report_checkbox">
              <Checkbox
                value={options.show_average}
                onChange={(e) => handleOptions('show_average', !options.show_average)}
                color="primary"
              />
              {I18n.t('show_averages')}
            </FormLabel>

            <FormLabel className="report_checkbox">
              <Checkbox
                value={options.show_total}
                onChange={(e) => handleOptions('show_total', !options.show_total)}
                color="primary"
              />
              {I18n.t('show_total')}
            </FormLabel>

            <FormLabel className="report_checkbox">
              <Checkbox
                value={options.show_empty}
                onChange={(e) => handleOptions('show_empty', !options.show_empty)}
                color="primary"
              />
              {I18n.t('show_empty_bars')}
            </FormLabel>

            <FormLabel className="report_checkbox">
              <Checkbox
                value={options.horizontal}
                onChange={(e) => handleOptions('horizontal', !options.horizontal)}
                color="primary"
              />
              {I18n.t('horizontal_bars')}
            </FormLabel>
          </div>
        </div>
      }

      { error && <p className="error">{I18n.t(error)}</p>}

      <div style={{ height: "50px" }} />
      <button
        className="btn btn-primary xl"
        onClick={() => handleReport()}
        disabled={
          template == undefined ||
          questionnaireIds.length < (template.min_questionnaires || 1) ||
          questionnaireIds.length > template.max_questionnaires ||
          error
        }
      >
        {I18n.t("T_show_report")}
      </button>
    </div >
  );
}

function FilterList({ filters, handleSelection, handleBarOrder, selectedFilters }) {
  const columns = [
    { Header: I18n.t('filter_name'), accessor: "name" },
    { Header: I18n.t('description'), accessor: 'description' },
  ]

  const getFilter = (id) => {
    return filters.find(f => f.id == id)
  }

  return (
    <>
      {
        (filters.length == 0) ? <div /> :

          <div>
            <Table
              columnDefinitions={columns}
              rawData={filters}
              useSelect={true}
              useGlobalSearch={filters.length > 10}
              setSelected={(rows, type) => handleSelection(rows, type)}
            />
          </div>
      }
      <div style={{ display: 'flex' }}>
        {selectedFilters != undefined && selectedFilters.map((f, index) =>
          <div className="filter_button">
            <button onClick={() => handleBarOrder(true, f)}>{index > 0 ? "<" : ""}</button>
            <div>{getFilter(f).name}</div>
            <button onClick={() => handleBarOrder(false, f)}>{index < selectedFilters.length - 1 ? ">" : ""}</button>
          </div>
        )}
      </div>
    </>
  )
}

function FilterBarList({ filters, handleBarOrder, selectedFilters, questionnaires }) {
  const getFilter = (id) => {
    var type = id[0];
    id = parseInt(id.substring(1));

    if (type == 'f') {
      return filters.find(f => f.id == id)
    } else {
      return questionnaires.find(q => q.id == id)
    }
  }

  return (
    <>
      <div>
        <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: "15px" }}>
          {selectedFilters != undefined && selectedFilters.map((f, index) =>
            <div key={"filter_object_" + f} className={"filter_button " + (f[0] == 'f' ? 'filter' : 'questionnaire')}>
              <button onClick={() => handleBarOrder(true, f)}>{index > 0 ? "<" : ""}</button>
              <div>{getFilter(f).name}</div>
              <button onClick={() => handleBarOrder(false, f)}>{index < selectedFilters.length - 1 ? ">" : ""}</button>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
