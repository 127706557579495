import { Controller } from "stimulus"


export default class extends Controller {
  static targets = ['show', "hide", 'element', 'base'];

  connect(){
    this.show_elements()
  }

  show_elements() {
    this.showTargets.forEach(element => {
      element.style.display = this.baseTarget.style.display
    });
    this.hideTargets.forEach(element => {
      element.style.display = 'none'
    });
    this.elementTargets.forEach(element => {
      element.style.display = this.baseTarget.style.display
    });
  }

  hide_elements() {
    this.showTargets.forEach(element => {
      element.style.display = 'none'
    });
    this.hideTargets.forEach(element => {
      element.style.display = this.baseTarget.style.display
    });
    this.elementTargets.forEach(element => {
      element.style.display = 'none'
    });
  }
  
  toggle() {
  
    if (this.showTargets[0].style.display == 'none') {
      this.show_elements();
    }
    else {
      this.hide_elements();
    }
  }

}