import React, { useEffect } from "react";
import { List, DialogContent, DialogActions, Divider } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";

import LoadingIndicator from "@components/loading_indicator";
import Theme from "@components/theme";
import DropdownMenu from "@components/dropdown_menu";
import QuestionnaireEventForm from "@views/questionnaire_event/form";
import QuestionnaireEventListItem from "@views/questionnaire_event/list_item";
import { defaultPostHeaders } from "@helpers/index";

// Form wrapped in dialog
function FormDialog(props) {
  const [formData, setFormData] = React.useState(
    props.formProps ? props.formProps : {}
  );
  const [error, setError] = React.useState();
  const [saving, setSaving] = React.useState(false);

  const handleSave = (e) => {
    if (formData.scheduled === true && formData.scheduled_at < Date.now()) {
      setError("time_cant_be_in_past");
      return;
    }
    if (
      formData.type == "freeze" ||
      formData.type == "feedback" ||
      formData.email_text.includes("$link$") ||
      confirm(I18n.t("confirm_missing_questionaire_link"))
    ) {
      setSaving(true);
      fetch(
        props.id
          ? props.routes["update"].replace("event_id", props.id.toString())
          : props.routes["create"],
        {
          method: props.id ? "UPDATE" : "POST",
          headers: defaultPostHeaders(),
          body: JSON.stringify({
            questionnaire_event: {
              ...formData,
              event_type: props.formProps.type,
            },
          }),
        }
      ).then((response) => {
        props.afterSave();
        Turbolinks.clearCache();
        if (response.status != 202) {
          setError("T_internal_error");
        } else {
          setError(undefined);
          props.closeDialog();
        }
        setSaving(false);
      });
    }
  };

  const handleClose = (e) => {
    props.closeDialog();
  };

  return (
    <div>
      <DialogContent>
        {error && <p className="error">{I18n.t(error)}</p>}
        <QuestionnaireEventForm
          {...props.formProps}
          lastMessages={props.lastMessages}
          formData={formData}
          setFormData={setFormData}
        />
      </DialogContent>
      <DialogActions>
        <button onClick={handleClose} className="btn btn-default xl">
          {I18n.t("cancel").toUpperCase()}
        </button>
        <button
          onClick={handleSave}
          className="btn btn-primary xl"
          disabled={saving}
        >
          {I18n.t("save").toUpperCase()}
        </button>
      </DialogActions>
    </div>
  );
}

// Items to render in dropdown menu
const menuItems = (afterSave, routes, options, lastMessages) => {
  return options.map((item) => {
    return {
      text: I18n.t(item),
      type: "dialog",
      dialogProps: {
        fullWidth: true,
        content: (closeDialog) => (
          <FormDialog
            formProps={{ type: item }}
            routes={routes}
            afterSave={afterSave}
            closeDialog={closeDialog}
            lastMessages={lastMessages}
          />
        ),
      },
    };
  });
};

export default function QuestionnaireEventList(props) {
  const { questionnaire_id, routes } = props;

  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState([]);
  const [editData, setEditData] = React.useState({});
  const [editOpen, setEditOpen] = React.useState(false);
  const [lastMessages, setLastMessages] = React.useState();

  const turbolinksBeforeCache = () => {
    setLoading(true);
  };
  const reload = () => {
    fetch(routes["list"]).then((response) => {
      response.json().then((json) => {
        setLoading(false);
        setData(json.events);
        setLastMessages(json.last_events);
      });
    });
  };

  useEffect(() => {
    reload();
  }, [questionnaire_id]);

  useEffect(() => {
    document.addEventListener("turbolinks:before-cache", turbolinksBeforeCache);
    return () =>
      document.removeEventListener(
        "turbolinks:before-cache",
        turbolinksBeforeCache
      );
  }, []);

  const afterSave = () => {
    setLoading(true);
    reload();
  };

  const editItem = (item) => {
    setEditOpen(true);
    setEditData({
      type: item.event_type,
      scheduled_at: item.scheduled_at,
      email_title: item.body.email_title,
      email_text: item.body.email_text,
      id: item.id,
      scheduled: 1,
    });
  };

  const removeItem = (item_id) => {
    fetch(props.routes.delete.replace("event_id", item_id.toString()), {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      headers: {
        "X-CSRF-Token": document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content"),
      },
    })
      .then((response) => {
        reload();
      })
      .catch((error) => console.log(error));
  };

  const closeDialog = () => {
    setEditOpen(false);
  };

  return (
    <Theme>
      <Dialog open={editOpen} fullWidth={true}>
        <FormDialog
          id={editData.id}
          formProps={editData}
          routes={routes}
          afterSave={afterSave}
          closeDialog={closeDialog}
        />{" "}
      </Dialog>
      <h1 className="inline">{I18n.t("events")}</h1>
      <div className="inline pull-right">
        <DropdownMenu
          buttonText={I18n.t("new_event")}
          buttonIcon="add"
          menuItems={menuItems(
            afterSave,
            routes,
            props.types.includes("hvvk")
              ? ["invite", "reminder", "freeze", "feedback"]
              : ["invite", "reminder", "freeze"],
            lastMessages
          )}
        />
      </div>

      {loading ? (
        <LoadingIndicator alignCenter />
      ) : (
          <div>
            <div>
              <List>
                {data.length > 0 ? (
                  data.map((d, idx) => {
                    return (
                      <div key={idx}>
                        <QuestionnaireEventListItem
                          key={`event-list-item-${idx}`}
                          {...d}
                          routes={routes}
                          removeItem={removeItem}
                          editItem={editItem}
                        />
                        <Divider variant="inset" component="li" />
                      </div>
                    );
                  })
                ) : (
                    <div>{I18n.t("no_events")}</div>
                  )}
              </List>
            </div>
          </div>
        )}
    </Theme>
  );
}
