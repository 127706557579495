import React, { useEffect } from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";
import { InputLabel } from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/fi";
import "moment/locale/sv";
import "moment/locale/ru";
import "moment/locale/et";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  border: {
    borderBottom: "1px solid #F1F1F1",
    padding: 15,
    paddingLeft: 40,
    paddingRight: 40,
  },
  normalText: {
    transform: "translate(0, 0) scale(1)",
  },
  textField: {
    width: 275,
  },
}));

export default function QuestionnaireValueDate(props) {
  const { tkey, str_id, saveAnswer, answer } = props;

  const [selectedDate, setSelectedDate] = React.useState(answer || null);
  const classes = useStyles();
  const identifier = `input-${str_id}`;

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (date && date.isValid()) {
      saveAnswer(str_id, date.format("YYYY-MM-DD"));
    }
  };

  useEffect(() => {
    moment.locale(props.language);
  });

  return (
    <div className={classes.border}>
      <InputLabel
        shrink
        htmlFor={identifier}
        className={classes.normalText}
        disabled={props.disabled}
      >
        {I18n.t(tkey)}
      </InputLabel>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
          id={identifier}
          className={classes.textField}
          inputVariant="outlined"
          format="DD.MM.YYYY"
          margin="dense"
          label=""
          value={selectedDate}
          onChange={handleDateChange}
          disabled={props.disabled}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
}
