import React, { useEffect } from 'react';
import Select from '@material-ui/core/Select';

export default function LangugeSelector({languages, current, url}){

  const handleLanguage = (language) => {
    if (language != current)
      if (!url.includes('language=')) {
        url += `${url.includes('?') ? '&' : '?'}language=${current}`
      }
      window.location.href = url.replace(`language=${current}`, `language=${language}`);
  }
  
  return(
    <div style={{ float: 'right', width: "100px", display: 'flex' }}>
      <div className="material-icons">language</div>
      <Select
        native
        value={current}
        onChange={e => handleLanguage(e.target.value)}
        >
          {languages.map((language) => (
            <option key={language} value={language}>{I18n.t(`T_${language}`)}</option>
          ))}
        </Select>
    </div>
  )
}