import React from "react";
import { Button, Grid, Checkbox, FormLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import OdumLogo from "images/odum_logo_big.png";

const useStyles = makeStyles({
  logo: {
    width: 120,
    marginTop: -60,
    marginBottom: 20,
  },
});

function renderInformationContent(_type) {
  var type = _type.toLowerCase();
  return (
    <div>
      <h1>{I18n.lookup("T_welcome_to_survey_" + type)}</h1>
      <p>{I18n.lookup(`T_welcome_story_${type}_1`)}</p>
      <p>{I18n.lookup(`T_welcome_story_${type}_2`)}</p>
      <p>{I18n.lookup(`T_welcome_story_${type}_3`)}</p>
      <h3>{I18n.lookup("T_questionnaire_motivation_title_" + type)}</h3>
      <p>{I18n.lookup("T_questionnaire_motivation_story_" + type)}</p>
      <h3>{I18n.lookup("T_questionnaire_confidentiality_title_" + type)}</h3>
      <p>{I18n.lookup("T_questionnaire_confidentiality_story_" + type)}</p>
    </div>
  );
}

function renderInformationDefaultContent(type) {
  return (
    <div>
      <h1>{I18n.t("T_welcome_to_survey")}</h1>
      <p>{I18n.t("T_welcome_story")}</p>
      <h3>{I18n.t("T_questionnaire_motivation_title")}</h3>
      <p>{I18n.t("T_questionnaire_motivation_story")}</p>
      <h3>{I18n.t("T_questionnaire_confidentiality_title")}</h3>
      <p>{I18n.t("T_questionnaire_confidentiality_story")}</p>
    </div>
  );
}

export default function QuestionnaireInformationPage(props) {
  const classes = useStyles();
  const { type } = props;
  const [eulaRead, setEulaRead] = React.useState(false);

  return (
    <Grid container direction="column" justify="center" alignItems="center">
      <Grid container direction="column" style={{ padding: 40 }}>
        {I18n.lookup("T_welcome_to_survey_" + type.toLowerCase())
          ? renderInformationContent(type)
          : renderInformationDefaultContent(type)}
        <FormLabel>
          <Checkbox
            checked={eulaRead}
            onChange={() => {
              setEulaRead(!eulaRead);
            }}
            value=""
            color="primary"
          />
          <span
            dangerouslySetInnerHTML={{ __html: I18n.t(props.userAgreement) }}
          ></span>
        </FormLabel>
      </Grid>

      <button
        className="btn btn-primary xl"
        onClick={props.nextPage}
        style={{ marginBottom: 40 }}
        disabled={!eulaRead}
      >
        {I18n.t("T_continue_to_enquiry")}
      </button>
    </Grid>
  );
}
