import { Controller } from "stimulus"


export default class extends Controller {
  static targets = ['delete', 'cancel', 'deleteToggle', 'text'];

  connect(){
    this.deleteTarget.style.display = 'block'
    this.cancelTarget.style.display = 'none'
    this.deleteToggleTarget.value = false
  }

  delete(){
    this.deleteTarget.style.display = 'none'
    this.cancelTarget.style.display = 'block'
    this.deleteToggleTarget.value = true
    this.textTargets.forEach(element => {
      element.style.color = 'red'
    });
  }

  cancel(){
    this.deleteTarget.style.display = 'block'
    this.cancelTarget.style.display = 'none'
    this.deleteToggleTarget.value = false
    this.textTargets.forEach(element => {
      element.style.color = 'black'
    });
  }
}
