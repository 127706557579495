import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    color: '#63A437',
    alignSelf: 'center',
    margin: '2rem',
  },
  center: {
    justifyContent: 'center',
    display: 'flex',
    flex: 1,
  }
}

LoadingIndicator.defaultProps = {
  alignCenter: false
}

function LoadingIndicator(props) {
  const {classes, alignCenter} = props;
  return <div className={alignCenter ? classes.center : ''}><CircularProgress className={classes.root} size={30} thickness={5} disableShrink /></div>
}

export default withStyles(styles)(LoadingIndicator);