import React, { useEffect, Fragment } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import {
  ListItem, ListItemSecondaryAction,
  ListItemText, IconButton, Icon,
  Avatar, ListItemAvatar, Collapse,
  List, CircularProgress, ListItemIcon
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import LoadingIndicator from '@components/loading_indicator';

const ICONS = {
  freeze: 'amp_stories',
  reminder: 'insert_invitation',
  invite: 'email',
  feedback: 'assignment'
}

const useStyles = makeStyles({
  freeze: {
    backgroundColor: '#009ECF',
  },
  reminder: {
    backgroundColor: '#4b5f83',
  },
  invite: {
    backgroundColor: '#63A437',
  },
  feedback: {
    backgroundColor: '#D5B229',
  },
  nested: {
    paddingLeft: 30,
    paddingTop: 0,
    paddingBottom: 0,
  }
});

const STATUS_ICONS = {
  'pending': <CircularProgress size={15} thickness={5} disableShrink />,
  'success': <Icon style={{ color: 'green' }}>check</Icon>,
  'error': <Icon style={{ color: 'red' }}>error_outline</Icon>,
  'empty': <Icon>remove</Icon>,
}

const STATUS_COLOR = {
  'pending': 'gray',
  'success': 'black',
  'error': 'red'
}

export default function QuestionnaireEventListItem(props) {
  const { event_type, scheduled_at, status } = props;
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);
  const scheduledAt = React.useMemo(() => moment(scheduled_at), [scheduled_at]);
  const classes = useStyles();

  const handleClick = () => {
    setOpen(!open);
  }

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const edit = () => {
    handleClose();
    props.editItem(props)
  }

  const remove = () => {
    handleClose();
    confirm(I18n.t("confirm_delete_event"))
    props.removeItem(props.id)
  }

  const options = ["edit", "delete"]

  return (
    <div style={{ marginTop: "2px" }} >
      <ListItem button onClick={handleClick} style={{ background: (open ? '#ddd' : 'transparent'), borderRadius: "5px 5px 0 0" }} >
        <ListItemAvatar>
          <Avatar className={classes[event_type]} style={{ opacity: scheduledAt > moment() ? 0.5 : 1 }}>
            <Icon>{ICONS[event_type]}</Icon>
          </Avatar>
        </ListItemAvatar>
        {props.event_type == 'feedback' && <div style={{ marginRight: "5px", position: 'absolute', left: "58px", top: "14px" }}>
          {['low', 'medium', 'high', 'undefined'].map((tier) => (
            props.target_groups[tier] ? <div className={tier} key={tier} style={{ width: "10px", height: "10px", borderRadius: "50%", marginBottom: "3px", border: "solid 1px gray" }} /> : null
          ))
          }
        </div>}

        <ListItemText
          primary={I18n.t(event_type)}
          secondary={scheduledAt.format('DD.MM.YYYY HH:mm')}
        />
        <ListItemIcon>
          <Icon style={{ minWidth: '42px' }}>{STATUS_ICONS[status]}</Icon>
        </ListItemIcon>
        {
          scheduledAt > moment() ?
            <ListItemSecondaryAction style={{ position: 'absolute' }}>
              <IconButton
                onClick={handleMenuClick}
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
              >
                <Icon>edit</Icon>
              </IconButton>
              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={menuOpen}
                onClose={handleClose}
              >
                <MenuItem onClick={edit} key={props.id + "edit"}>{"Muokkaa"}</MenuItem>
                <MenuItem onClick={remove} key={props.id + "remove"}>{"Poista"}</MenuItem>
              </Menu>
            </ListItemSecondaryAction>
            :
            <div></div>
        }
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <RecipientList type={event_type} route={props.additional_info_path} title={props.body.email_title} text={props.body.email_text} messages={props.message} />
      </Collapse>
    </div>
  )
}


function RecipientList(props) {
  const [values, setValues] = React.useState({ recipients: [] });
  const [loading, setLoading] = React.useState(true);
  const classes = useStyles();

  useEffect(() => {
    fetch(props.route).then((response) => {
      response.json().then((json) => {
        setLoading(false);
        setValues(json);
      })
    })
  }, [props.route]);

  return (
    <List component="div" disablePadding style={{ border: "solid 1px #ccc", borderRadius: "0px 0px 5px 5px" }}>
      {values.recipients.length == 0 && !loading && moment(values.scheduled_at) > moment() && (
        <ListItem className={classes.nested} style={{ color: 'red' }}>
          <ListItemText primary={I18n.t('event_not_processed_explanation')} />
        </ListItem>)
      }
      {props.title &&
        <ListItem className={classes.nested}>
          <ListItemText primary={"Viesti: " + props.title} />
        </ListItem>
      }
      {props.text &&
        <ListItem className={classes.nested}>
          <ListItemText style={{ border: "solid 1px lightgray", padding: "5px", whiteSpace: "pre-line" }} primary={props.text} />
        </ListItem>
      }
      {loading && <LoadingIndicator alignCenter />}
      <List style={{ columnCount: 2 }}>
        {
          values.recipients.length > 0 ?
            values.recipients.map((v, idx) => (
              <ListItem key={`recipient-${idx}`} className={classes.nested} style={{ width: 'min-content' }}>
                <ListItemIcon style={{ minWidth: '25px' }}>
                  <Icon>{STATUS_ICONS[v.status]}</Icon>
                </ListItemIcon>
                <ListItemText primary={v.email} style={{ color: STATUS_COLOR[v.status], whiteSpace: "nowrap" }} />
              </ListItem>
            ))
            :
            <ListItem className={classes.nested}>{props.type != 'freeze' ? I18n.t('no_recipients') : ''}</ListItem>
        }
      </List>
      {
        <div style={{ paddingLeft: "25px" }}>
          {(props.messages || "").split(',').filter((e) => e.length > 0).map((message) => (
            <div key={message} className="error" style={{ margin: "5px" }}>{"-" + I18n.t(message)}</div>
          ))}
        </div>
      }
    </List>
  )
}