import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';

import Theme from '@components/theme';
import Dialog from '@components/dialog';
import EmployeeImport from '@views/employee/import';

import Icon from '@material-ui/core/Icon';

DropdownMenu.defaultProps = {
  buttonText: 'Open menu',
  buttonIcon: null,
  menuItems: []
}

const OhsButton = withStyles({
  root: {
    borderRadius: 0,
    boxShadow: 'none',
    padding: '0.25rem 0.5rem',
    lineHeight: 1.5,
    fontWeight: 400,
    fontFamily: [
      'Open Sans',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontSize: 12,
    textTransform: 'none',

    '&:hover': {
      boxShadow: 'none'
    }
  }
})(Button);

export default function DropdownMenu(props){
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { buttonText, buttonIcon, menuItems } = props;

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Theme>
        <OhsButton
          aria-controls="customized-menu"
          aria-haspopup="true"
          variant="contained"
          color="secondary"
          onClick={handleClick}
          className="secondary"
        >
          {buttonIcon && <Icon>{buttonIcon}</Icon>}
          {buttonText}
        </OhsButton>
        <Menu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          elevation={1}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}      
        >
          {menuItems.map((item,idx) => (
            <MenuItem disableGutters={true} key={`menu-item-${idx}`}>
              {
                item.type === 'dialog' ?
                  <Dialog dialogBtnClass="menu-item-btn" dialogTitle={item.text} dialogBtnText={item.text} {...item.dialogProps} onClick={handleClose}/>
                :
                item.type === 'import' ?
                    <EmployeeImport dialogBtnClass="menu-item-btn" dialogTitle={item.text} dialogBtnText={item.text} mode={item.importMode} {...item.dialogProps} onClick={handleClose} />
                  :
                <ListItemText primary={item.text} />
              }
            </MenuItem>
          ))}
        </Menu>
      </Theme>
    </div>
  );

}

