import React, { useEffect } from 'react';
import { FormLabel, Slider } from '@material-ui/core';

import Textile from '../common/textile';

function getMarksFromOptions(options){
  return options.map((option, idx) => { 
    // Only show the label for the first and the last
    return { 
      value: option.value,
      label: idx == 0 || idx == options.length - 1 ? I18n.t(option.tkey) : ''
    }
  })
}


export default function QuestionnaireValueSlider(props) {
  const { options, tkey, str_id, saveAnswer } = props;
  const [value, setValue] = React.useState(props.answer || 0);

  const marks = React.useMemo(() => getMarksFromOptions(options), [props]);

  const commitChange = (event, val) => {
    setValue(val);
    saveAnswer(str_id, val);
  };

  const handleChange = (event, val) => {
    setValue(val);
  }
  useEffect(() => {
    if (props.answer != value)
      commitChange(null, props.answer || 0)
  }, [props])

  return (
    <div>
      <FormLabel><Textile text={I18n.t(tkey)}/></FormLabel> 
      <Slider
        style={{marginTop: 30}}
        value={typeof value === 'number' ? value : 0}
        onChange={handleChange}
        onChangeCommitted={commitChange}
        aria-labelledby="discrete-slider-restrict"
        marks={marks}
        max={Math.max(...marks.map((m) => m.value))}
        min={Math.min(...marks.map((m) => m.value))}
        valueLabelDisplay="on"
        disabled={props.disabled}
        step={null}
      />
    </div>
  )
}