import React from "react";
import { Button, Icon, TextField, Tooltip, Select, MenuItem} from "@material-ui/core/";

export default function Options(props) {

  function renderColorOptions() {
    return ['red', 'yellow', 'green', 'none'].map((key, index) => {
      return (
        <MenuItem value={key} key={key}>
          {key}
        </MenuItem>
      );
    });
  }

  return (
    <div>
      {props.options && props.options.length > 0 && (
        <div>
          <h3>{"Options"}</h3>
          {props.options.map((option, index) => (
            <div key={`option_${props.question.id}_${index}`} className="row">
              <div className="col-1">{`[${option.value}] `}</div>
              <TextField
                className="col-3"
                value={option.tkey}
                onChange={(e) =>
                  props.handleOptionValue(
                    props.questionIndex,
                    index,
                    "tkey",
                    e.target.value
                  )
                }
              />
              <div className="col-8">{`${I18n.t(option.tkey)}`}</div>
              {props.question.color_report && 
                <div><Select
                value={option.color || 'none'}
                style={{ width: "100px" }}
                onChange={(e) =>
                  props.handleOptionValue(
                    props.questionIndex,
                    index,
                    "color",
                    e.target.value
                  )
                }
                >{renderColorOptions()}</Select></div>
              }
            </div>
          ))}
        </div>
      )}
      <Tooltip title="Add option">
        <Button
          className="edit_button"
          onClick={() =>
            props.handleOptions(props.questionIndex, "add", props.options)
          }
        >
          <Icon>add</Icon>
        </Button>
      </Tooltip>
      {props.options && props.options.length > 0 && (
        <Tooltip title="Remove last option">
          <Button
            className="edit_button"
            onClick={() =>
              props.handleOptions(props.questionIndex, "remove", props.options)
            }
          >
            <Icon>removeCircle</Icon>
          </Button>
        </Tooltip>
      )}
    </div>
  );
}
