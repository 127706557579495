import React from 'react';
import QuestionnaireValueShortText from './questionnaire_value_short_text';

export default function QuestionnaireValueInteger(props) {
  // TODO: Only allow numerical values
  const inputType = "number"
  return (
    <QuestionnaireValueShortText inputType={inputType} {...props}
    />
  )
  
}