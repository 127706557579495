import React from 'react';
import Table from "@components/table";
import AuthenticityToken from '@components/authenticity_token';

export default function ParticipantSelection(props) {
  
  const [selected, setSelected] = React.useState([]);


  const handleSelect = (items) => {
    setSelected(items.map((i) => i.id));
  }

  return(
    <div>
      <Table useSelect={true} {...props} onSelect={handleSelect} />

      {props.noForm ?
        <div>
          {selected.map((selected, idx)=> <input key={idx}type="hidden" value={selected} name='selected_ids[]' />)}        
        </div>
        :
        <form action={props.submitPath} method="POST">
          <AuthenticityToken/>
          {selected.map((selected, idx)=> <input key={idx}type="hidden" value={selected} name='selected_ids[]' />)}        
        </form>
      }

    </div>
  )
}