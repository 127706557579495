import React from "react";

import Container from "@material-ui/core/Container";
import MuiGrid from "@material-ui/core/Grid";
import { styled } from "@material-ui/core/styles";

import {
  QuestionnaireTemplatePage,
  QuestionnaireWelcomePage,
  QuestionnaireThankyouPage,
  QuestionnaireClosingPage,
  QuestionnaireInformationPage,
  ProgressIndicator,
} from "@components/questionnaire/index";

import { groupByAndSort } from "@components/questionnaire/helper";

import Theme from "@components/theme";

const PAGES = {
  QuestionnaireTemplatePage,
  QuestionnaireWelcomePage,
  QuestionnaireThankyouPage,
  QuestionnaireClosingPage,
  QuestionnaireInformationPage,
};

const Grid = styled(MuiGrid)({
  background: "#FFFFFF",
  marginTop: 80,
});

export default class Questionnaire extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: null,
      hasNextPage: false,
      hasPrevPage: false,
      pages: [],
      questions: {},
      answers: props.answers || {},
      language: props.availableLanguages ? props.availableLanguages[0] : (props.language ? props.language : "fi"),
      message: "",
    };
  }

  // Sort the questions and set the first page
  loadQuestions = () => {
    // TODO: Maybe should be async?
    const { formData, ignored_fields, custom_questions } = this.props;
    let pages = [...formData.pages].sort((a, b) => a.sort_order - b.sort_order);

    // Custom questions
    var new_questions = [];
    if (custom_questions && custom_questions.length > 0) {
      let closingIndex = pages.findIndex((page) => {
        return page.type == "QuestionnaireClosingPage";
      });
      if (closingIndex == -1) {
        closingIndex = pages.length;
      }
      pages.splice(closingIndex, 0, {
        id: closingIndex + 1,
        type: "QuestionnaireTemplatePage",
        sort_order: closingIndex + 1,
        template_name: "custom questions",
      });
      for (var i = 0; i < pages.length; i++) {
        pages[i].sort_order = i + 1;
        //pages[i].id = i + 1;
      }
      let title = [
        {
          tkey: "free_form_questions",
          type: "QuestionnaireCategoryTitle",
          sort_order: 0,
          questionnaire_page: closingIndex + 1,
          custom_text: "custom_title",
        },
      ];

      let customs = custom_questions.map((question) => ({
        tkey: question[this.state.language],
        fi: question["fi"],
        en: question["en"],
        sv: question["sv"],
        et: question["et"],
        ru: question["ru"],
        type: "QuestionnaireValueLongText",
        sort_order: question.sort_order + 1,
        questionnaire_page: closingIndex + 1,
        options: [],
        str_id: question.key,
        translate: false,
      }));
      new_questions = customs
        .concat(title)
        .sort((a, b) => a.sort_order - b.sort_order);
    }

    formData.questions = formData.questions.concat(new_questions);
    let questions = groupByAndSort(
      [...formData.questions],
      (a) => a.questionnaire_page,
      (a, b) => a.sort_order - b.sort_order,
      ignored_fields
    );

    if (this.props.view_only) {
      pages = pages.filter((page) => page.type == "QuestionnaireTemplatePage");
    } else if (this.props.user_view) {
      var wantedPages = [
        "QuestionnaireClosingPage",
        "QuestionnaireTemplatePage",
      ];
      pages = pages.filter((page) => wantedPages.includes(page.type));
    }

    var startingPage = pages.findIndex(function (page) {
      return page.type === "QuestionnaireTemplatePage";
    });
    // Load the default keys and merge to saved ones
    let answers = Object.assign(
      {},
      ...formData.questions.map((q) => q.str_id).map((k) => ({ [k]: null }))
    );
    answers = { ...answers, ...this.state.answers };

    let initialConditions = {};
    formData.questions.forEach((q) => {
      if (q.type == "QuestionnaireValueRadio" && answers[q.str_id] != null) {
        answers[q.str_id] = parseInt(answers[q.str_id]);
      }

      if (q.conditional) {
        if (answers[q.str_id]) {
          initialConditions[q.conditional] = q.condition;
        } else if (!initialConditions[q.conditional]) {
          initialConditions[q.conditional] = q.condition;
        }
      }
    });

    this.setState({
      questions: questions,
      answers: answers,
      initialConditions: initialConditions,
      pages: pages,
      currentPage: pages[0],
      hasNextPage: pages.length > 1,
      pageNum: 0,
      startingPage: startingPage,
    });
  };

  savepage = (pageNum) => {
    var answers = this.state.answers;
    var questions = this.state.questions.get(this.state.currentPage.sort_order);
    var mandatoryMissing = false;

    if (questions) {
      questions.forEach((question) => {
        if (
          question.mandatory &&
          (answers[question.str_id] == null ||
            answers[question.str_id].length === 0)
        ) {
          // Question has depency that should be checked

          if (question.dependancy) {
            // NOTE: Temporary solution to check for dependancy values
            // TODO: Improve this if more detailed dependancies are required
            // First part is dependancy key and second is value that skips questions that are dependant on it
            var dependancies = question.dependancy.split('&&')
            dependancies.forEach((dependancy, idx) => {
              var parts = dependancy.split('!=')
              if (parts && parts.length > 1) {
                var dependancy_key = parts[0];
                var dependancy_value = parts[1];
                if (answers[dependancy_key] && (answers[dependancy_key] == dependancy_value)) {
                  return;
                }
                if ( idx == dependancies.length-1 && answers[dependancy_key] && (answers[dependancy_key] != dependancy_value)) {
                  mandatoryMissing = true;
                }
              }
            })
          } else {
            mandatoryMissing = true;
          }
        }
      });
      if (
        mandatoryMissing &&
        !confirm(I18n.t("mandatory_missing_proceed_anyway"))
      ) {
        return false;
      }
    }

    var data = {
      authenticity_token: document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
    };
    data.answers = {};
    data.personal = {};
    data.completed = pageNum == this.state.pages.length - 1;

    var props = this.state;

    for (var e in props.answers) {
      var value = props.answers[e];
      if (value === null) {
        continue;
      } else if (e.startsWith("person_")) {
        data.personal[e] = value;
      } else {
        data.answers[e] = value;
      }
    }
    data.answers.language = this.state.language;
    this.setState({
      message: "",
    });
    fetch(window.location.href, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status != 204) {
          if (response.status == 403) {
            Turbolinks.clearCache();
            Turbolinks.visit(
              this.props.questionnaire_link + "?error=session_expired"
            );
          } else {
            response.text().then((body) => {
              this.setState({
                message: I18n.t("survey_save_failed"),
              });
            });
          }
        } else {
          this.setState({
            message: undefined,
          });
        }
      })
      .catch((error) => {
        this.setState({
          message: response.message
            ? response.message
            : I18n.t("survey_save_failed"),
        });
      });
    return true;
  };

  changePage = (pageNum) => {
    if (!this.props.view_only) {
      if (!this.savepage(pageNum)) {
        return;
      }
    }

    const { pages } = this.state;
    this.setState({
      pageNum: pageNum,
      currentPage: pages[pageNum],
      hasNextPage: pages.length - 1 > pageNum,
      hasPrevPage: pageNum > 0,
      lastPage: pageNum == this.state.pages.length - 1,
    });

    window.scrollTo(0, 0);
  };

  setLanguage = (language) => {
    I18n.locale = language;
    this.setState({ language });
  };

  // The component must validate the data
  saveAnswer = (key, value) => {
    if (this.props.view_only) {
      return;
    }
    let answers = { ...this.state.answers };
    if (answers.hasOwnProperty(key)) answers[key] = value;
    else
      throw "saveAnswer called with a key that doesn't exist in the question data";

    //if (value == false || value == 0) {
    // Remove answers from questions that require this answer
    this.state.questions.forEach((questionPage) => {
      questionPage.forEach((question) => {
        if (question.dependancy) {
          var dependancies = question.dependancy.split('&&');
          dependancies.forEach((dependancy) => {
            var parts = dependancy.split('!=');
            if (parts && parts.length > 1) {
              var dependancy_key = parts[0];
              var dependancy_value = parts[1];
              if (dependancy_key === key && answers[key] === dependancy_value) {
                answers[question.str_id] = null;
                return;
              }
            }
          });
        }
      });
    });
    //}
    this.setState({ answers });
  };

  componentDidMount() {
    this.loadQuestions();
  }

  render() {
    const {
      currentPage,
      hasPrevPage,
      hasNextPage,
      questions,
      pages,
      answers,
      initialConditions,
    } = this.state;
    const { availableLanguages, type, template } = this.props;

    // TODO Slice to components/render functions
    if (currentPage) {
      const Page = PAGES[currentPage.type];

      return (
        <Container maxWidth="md">
          <Theme>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <ProgressIndicator
                pages={pages}
                currentPage={currentPage}
                changePage={this.changePage}
                startingPage={this.state.startingPage}
              />
              {this.state.message && (
                <div
                  style={{
                    width: "100%",
                    color: "red",
                    textAlign: "center",
                    margin: "10px",
                  }}
                  dangerouslySetInnerHTML={{ __html: this.state.message }}
                />
              )}
              <Page
                key={"page_" + currentPage.sort_order}
                id={currentPage.sort_order}
                type={template.str_id}
                calculations={template.calculations}
                questions={questions.get(currentPage.sort_order)}
                warnings={this.state.warnings || []}
                answers={answers}
                initialConditions={initialConditions}
                availableLanguages={template.languages}
                setLanguage={this.setLanguage}
                language={this.state.language}
                saveAnswer={this.saveAnswer}
                nextPage={() => {
                  this.changePage(currentPage.sort_order);
                }}
                changePage={this.changePage}
                pages={pages}
                disabled={this.props.view_only}
                loggedIn={this.props.logged_in}
                returnLink={this.props.return_link}
                exitLink={this.props.exit_link}
                userAgreement={this.props.user_agreement}
                strongAuthentication={this.props.template.strong_authentication}
              >
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  style={{ padding: 40 }}
                >
                  <button
                    className="btn btn-default xl"
                    onClick={() => this.changePage(this.state.pageNum - 1)}
                    disabled={!hasPrevPage}
                  >
                    {I18n.t("T_previous_page")}
                  </button>
                  <button
                    className="btn btn-primary xl"
                    onClick={() => this.changePage(this.state.pageNum + 1)}
                    disabled={!hasNextPage}
                  >
                    {I18n.t("T_next_page")}
                  </button>
                </Grid>
              </Page>
            </Grid>
          </Theme>
        </Container>
      );
    }
    // TODO: Loader?
    return null;
  }
}
