import { Controller } from "stimulus"


export default class extends Controller {
  async post(event){
    event.preventDefault();
    await fetch(this.element.getAttribute('href'), {
      method: 'POST', 
      headers: this.getCorsPayload()
    }).then(function(response){
      // Redirect browser if redirected (access denied, logout, etc...)
      if (response.redirected){
        window.location.href = response.url;
      }
    })
  }

  visit(event){
    event.preventDefault();
    Turbolinks.visit(this.data.get('path'));
  }

  getCorsPayload() {
    return {'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')}
  }
}
