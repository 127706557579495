import { Controller } from "stimulus"


export default class extends Controller {
  static targets = ['menu'];

  toggle(e){
    e.stopPropagation();
    e.preventDefault();
    this.menuTarget.classList.toggle('show');
  }

  close(){
    this.menuTarget.classList.remove('show');
  }
}
