import React, { useEffect } from 'react';

function getInitialState(states, activeConditions, condition){
  return activeConditions && condition ? (states.find((s) => s.value == activeConditions[condition]) || states[0]) : states[0]
}

export default function QuesitonnaireConditionChanger(props){
  const {condition, states, updateActiveCondition, activeConditions} = props;
  const [state, setState] = React.useState(getInitialState(states, activeConditions, condition));

  useEffect(()=>{
    setState(getInitialState(states, activeConditions, condition));
  }, [activeConditions])

  const handleToggle = e => {
    e.preventDefault(); e.stopPropagation();
    const newState = states.find((e) => e.value != state.value);
    updateActiveCondition(condition, newState.value);
    setState(newState);
  }

  return <a onClick={handleToggle}>{I18n.t(state.tkey)}</a>

}