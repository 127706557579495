import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  paper: {
    background: "#79b711", //'#61a337',
    borderRight: "none",
    justifyContent: "space-between",
    paddingTop: 48,
    overflowX: "hidden",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7),
    // [theme.breakpoints.up('sm')]: {
    //   width: theme.spacing(9) + 1,
    // },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // ...theme.mixins.toolbar,
    background: "#92d566",
    color: "#FFF",
    zIndex: 999,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  listItem: {
    padding: 0,
  },
  listIcon: {
    color: "#FFF",
    padding: 12,
  },
  icon: {
    fontSize: "2.75rem",
  },
  whiteBg: {
    position: "absolute",
    left: 56,
    width: drawerWidth - 56,
    background: "#FFF",
    top: 0,
    bottom: 0,
  },
  text: {
    padding: 12,
    fontSize: "1.1rem",
    fontFamily: "OpenSans",
    letterSpacing: "0.1rem",
    color: "#666",
  },
  listIconActive: {
    padding: 12,
    background: "#FFF",
    color: "#61a337",
  },
  activeItem: {
    borderRight: "3px solid #61a337",
  },
  subItemIcon: {
    margin: "9px 15px",
    minWidth: "unset",
  },
  subItemIconActive: {
    padding: "4px",
    margin: "4px 11px",
    background: "#61a337",
    borderRadius: "100%",
  },

  subIcon: {
    fontSize: "25px",
    color: "#61a337",
  },
  subMenuText: {
    margin: 0,
    marginLeft: 24,
    borderLeft: "2px solid rgba(0, 0, 0, 0.08)",
    display: "flex",
  },
  activeSubIcon: {
    color: "#FFF",
  },
  submenu: {
    padding: 0,
    background: "#FFF",
    paddingBottom: 5,
  },
  listItem: {
    padding: 0,
    margin: 0,
  },
  subListItem: {
    padding: 0,
    margin: 0,
  },

  dot: {
    border: "2px solid rgba(0,0,0,0.08)",
    borderRadius: "100%",
    background: "#FFF",
    width: 12,
    height: 12,
    position: "relative",
    left: -19,
    top: 4,
    display: "block",
  },

  activeDot: {
    borderColor: "#61a337",
  },

  toolbarIcon: {
    color: "#FFF",
  },
}));

const ChevronRightIcon = () => <Icon>{"chevron_right"}</Icon>;
const ChevronLeftIcon = () => <Icon>{"chevron_left"}</Icon>;

export default function SideBar({ items }) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(
    sessionStorage.getItem("drawer-open") === "false" ? false : true
  );
  const [locale, setLocale] = React.useState(I18n.locale);

  useEffect(() => {
    // TODO: Remove this temp solution
    if (I18n.locale != "fi") {
      setLocale("fi");
      I18n.locale = "fi";
    }

    let elem = document.querySelector("#main-content");
    // To avoid the initial position of the content to have animations
    setTimeout(() => {
      elem.classList.add("sidebar-collapsable");
    }, 300);

    open
      ? elem.classList.add("sidebar-open")
      : elem.classList.remove("sidebar-open");
  }, [open, locale]);

  const toggleDrawerOpen = () => {
    sessionStorage.setItem("drawer-open", !open);
    setOpen(!open);
  };

  const navigate = (path) => {
    if (path.includes(".pdf")) {
      var win = window.open(path, "_blank");
      win.focus();
    } else {
      Turbolinks.visit(path);
    }
  };

  return (
    <div className={classes.root} style={{ paddingTop: 60 }}>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx(classes.paper, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        PaperProps={{ elevation: 2 }}
        open={open}
      >
        <div className={classes.whiteBg} />

        <List>
          {items.map((item, index) => (
            <div key={item.id}>
              <ListItem
                className={`${classes.listItem} ${
                  item.active ? classes.activeItem : ""
                }`}
                disableGutters={true}
                button
                onClick={() => {
                  navigate(item.path);
                }}
              >
                <ListItemIcon
                  className={
                    item.active ? classes.listIconActive : classes.listIcon
                  }
                >
                  <Icon className={classes.icon}>{item.icon}</Icon>
                </ListItemIcon>
                <ListItemText
                  className={classes.text}
                  disableTypography={true}
                  primary={I18n.t(`sidebar_${item.id}`).toUpperCase()}
                />
              </ListItem>
              {item.submenu.length > 0 && (
                <List className={classes.submenu}>
                  {item.submenu.map((subitem) => (
                    <ListItem
                      className={`${classes.subListItem} ${
                        open ? classes.activeItem : ""
                      }`}
                      disableGutters={true}
                      button
                      key={subitem.id}
                      onClick={() => {
                        navigate(subitem.path);
                      }}
                    >
                      <ListItemIcon
                        className={`${
                          subitem.active ? classes.subItemIconActive : ""
                        } ${classes.subItemIcon}`}
                      >
                        <Icon
                          className={`${classes.subIcon} ${
                            subitem.active ? classes.activeSubIcon : ""
                          }`}
                        >
                          {subitem.icon}
                        </Icon>
                      </ListItemIcon>
                      <ListItemText
                        className={`${classes.subMenuText} ${classes.text}`}
                        disableTypography={true}
                      >
                        <span
                          className={`${classes.dot} ${
                            subitem.active ? classes.activeDot : ""
                          }`}
                        ></span>
                        <div className={classes.root}>
                          {I18n.t(`sidebar_${subitem.id}`).toUpperCase()}
                        </div>
                      </ListItemText>
                    </ListItem>
                  ))}
                </List>
              )}
            </div>
          ))}
        </List>

        <div className={classes.toolbar}>
          <IconButton
            className={classes.toolbarIcon}
            onClick={toggleDrawerOpen}
          >
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
      </Drawer>
    </div>
  );
}
