import React, { Fragment } from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import LoadingIndicator from "@components/loading_indicator";
import { defaultPostHeaders } from "@helpers/index";

import XLSX from "xlsx";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";

export default function EmployeeImport(props) {
  const [open, setOpen] = React.useState(false);
  const [highlight, setHighlight] = React.useState(false);
  const [importData, setImportData] = React.useState("");
  const [filename, setFilename] = React.useState("");
  const [response, setResponse] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [status, setStatus] = React.useState(null);
  const [reload, setReload] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState(moment());
  const [frozeDate, setFrozeDate] = React.useState(moment());

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (loading) {
      return;
    }

    setResponse(null);
    setImportData("");
    setStatus("");
    setLoading(false);
    setOpen(false);
    if (reload) {
      Turbolinks.clearCache();
      Turbolinks.visit("");
    }
  };

  const handleImport = () => {
    setLoading(true);
    fetch(
      props.routes.import +
      ("?mode=" + props.mode + "&questionnaire_id=" + props.questionnaire),
      {
        body: JSON.stringify({ data: importData, name: filename, created_at: selectedDate, frozen_at: frozeDate }),
        method: props.method || "post",
        headers: defaultPostHeaders(),
      }
    ).then((response) => {
      if (response.status == 500) {
        setResponse([I18n.t("T_internal_error")]);
        setLoading(false);
        return;
      }
      response
        .json()
        .then((json) => {

          if (json.failed) {
            setStatus(I18n.t(json.failed));
            setResponse(json.errors);
            setLoading(false);
            return;
          }

          setResponse(json.result);
          setStatus(I18n.t("excel_import_result"));
          setLoading(false);
          setReload(true);
        })
        .catch((error) => {
          setStatus("");
          setLoading(false);
          setResponse([I18n.t("file_import_failed"), error.toString()]);
        });
    });
  };

  const handleDrop = (e) => {
    e.stopPropagation();
    handleFile(e);
  };

  const handleFile = (e) => {
    e.preventDefault();
    setHighlight(false);

    var files = e.target.files,
      f = files[0];
    setFilename(f.name.split(".")[0]);
    var reader = new FileReader();
    reader.onload = function (e) {
      var data = new Uint8Array(e.target.result);
      let workbook = XLSX.read(data, { type: "array" });
      var worksheet = workbook.Sheets[workbook.SheetNames[0]];
      data = XLSX.utils.sheet_to_json(worksheet, { defval: "-" });
      for (var i = 0; i < data.length; i++) {
        data[i].row = data[i].__rowNum__;
      }
      setImportData(data);
    };
    reader.readAsArrayBuffer(f);
  };

  const handleDragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setHighlight(true);
  };

  return (
    <Fragment>
      <button
        className={
          props.dialogBtnClass ? props.dialogBtnClass : "btn btn-secondary"
        }
        onClick={handleClickOpen}
      >
        {I18n.t("import_button")}
      </button>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
      >
        {!loading && response == null ? (
          <div>
            <DialogTitle id="alert-dialog-slide-title">
              {I18n.t("import_from_excel")}
            </DialogTitle>
            <DialogContent>
              <div
                className="import-drop"
                style={{ borderColor: highlight ? "blue" : "" }}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
              />
              {props.dateSelect &&
                <div>
                  <MuiPickersUtilsProvider utils={MomentUtils} locale={'fi'}>
                    <KeyboardDatePicker
                      inputVariant="outlined"
                      format="DD.MM.YYYY"
                      margin="dense"
                      label={I18n.t('T_created_at')}
                      value={selectedDate}
                      onChange={(e) => {
                        setSelectedDate(e);
                        setFrozeDate(e);
                      }}
                      ampm={false}
                    />
                    <KeyboardDatePicker
                      inputVariant="outlined"
                      format="DD.MM.YYYY"
                      margin="dense"
                      label={I18n.t('T_frozen')}
                      value={frozeDate}
                      onChange={(e) => setFrozeDate(e)}
                      ampm={false}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              }
            </DialogContent>
            <DialogContent>
              <input type="file" onChange={handleFile} />
            </DialogContent>
          </div>
        ) : null}
        {loading ? (
          <div>{loading && <LoadingIndicator alignCenter />}</div>
        ) : null}
        <DialogContent>
          <ImportResults data={response} status={status} />
        </DialogContent>
        <DialogActions>
          {!loading && response == null ? (
            <Fragment>
              <button
                className="btn xl btn-default"
                onClick={handleClose}
                color="primary"
              >
                {I18n.t("cancel")}
              </button>
              <button
                className="btn btn-primary xl"
                onClick={handleImport}
                dcolor="primary"
                disabled={importData == ""}
              >
                {I18n.t("import_execute")}
              </button>
            </Fragment>
          ) : (
              <button
                className="btn xl"
                onClick={handleClose}
                color="primary"
                disabled={loading}
              >
                {I18n.t("continue")}
              </button>
            )}
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

function ImportResults(props) {
  if (props.data == null) {
    return null;
  }

  return (
    <ul>
      <h1>{props.status}</h1>
      {props.data.map((item, i) => (
        <li key={i} style={{ color: "red" }}>
          <p>{item.toString()}</p>
        </li>
      ))}
    </ul>
  );
}
