import React, { useEffect } from 'react';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Slider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Textile from '../common/textile';
import QuestionnaireValueSlider from './questionnaire_value_slider';


const useStyles = makeStyles(theme => ({
  border: {
    borderBottom: '1px solid #F1F1F1',
    padding: 15,
    paddingLeft: 40,
    paddingRight: 40,
  },
}));


function shouldUseSlider(options) {
  return options.length > 3 && options.every((option) => I18n.t(option.tkey).length <= 3);
}

export default function QuestionnaireValueRadio(props) {
  const { options, tkey, str_id, saveAnswer } = props;
  const classes = useStyles();
  const [value, setValue] = React.useState(props.answer === null ? "" : props.answer);
  const useSlider = React.useMemo(() => shouldUseSlider(options), [props]);

  const handleChange = (event) => {
    const newValue = parseInt(event.target.value,10);
    setValue(newValue);
    saveAnswer(str_id, newValue);
  };

  useEffect(() => {
    setValue(props.answer === null ? "" : props.answer)
  }, [props])

  return (
    <div className={classes.border}>
      { useSlider ?
        <QuestionnaireValueSlider {...props} />
        :
        <FormControl component="fieldset">
          <FormLabel><Textile text={I18n.t(tkey)}/></FormLabel>
            <RadioGroup value={value} aria-label={str_id} name={str_id} onChange={handleChange}>
              {options.map((option,idx) => ( 
                <FormControlLabel 
                  key={`${str_id}_option_${idx}`} 
                  value={option.value} 
                  control={<Radio color="primary"/>} 
                  label={I18n.t(option.tkey)} 
                  disabled={props.disabled}
                  />
              ))}
            </RadioGroup>
        </FormControl>
      }

    </div>
  )
}
