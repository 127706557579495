import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  unfinished: {
    backgroundColor: "rgba(0, 158, 207, 0.2)",
  },
  done: {
    backgroundColor: "#79B611",
  },
  active: {
    backgroundColor: "#009ECF",
  },
  wrapper: {
    display: "flex",
    flex: 1,
  },
  bar: {
    display: "flex",
    flex: 1,
    height: 20,
    borderRight: "1px solid #FFF",
  },
});

export default function ProgressIndicator(props) {
  const { pages, currentPage, changePage } = props;
  const classes = useStyles();

  let filteredPages = [...pages].filter(
    (val) => val.type === "QuestionnaireTemplatePage"
  );

  if (currentPage.type !== "QuestionnaireTemplatePage") {
    return null;
  }

  return (
    <div className={classes.wrapper}>
      {filteredPages.map((page, idx) => {
        let barTypeClass =
          currentPage.sort_order === page.sort_order ? classes.active : null;
        barTypeClass =
          barTypeClass || (page.done ? classes.done : classes.unfinished);
        return (
          <span
            key={`bar-${idx}`}
            onClick={() => {
              changePage(idx + props.startingPage);
            }}
            className={`${classes.bar} ${barTypeClass}`}
          ></span>
        );
      })}
    </div>
  );
}
