import React, { useEffect, useState } from 'react';
import Icon from '@material-ui/core/Icon';
import moment from "moment";

const LOCKED_FIELDS = { 'gender': true, 'birthdate': true }

export default function EmployeeForm({ employee, fields, custom_fields, employee_fields, ssid_locked }) {

  const [first, setFirst] = React.useState(true);
  const [isValidSsid, setIsValidSsid] = React.useState()
  const [fieldValues, setFieldValues] = React.useState({})

  useEffect(() => {
    if (first) {
      setFirst(false);
      var data = {}
      fields.forEach(f => { data[f] = employee[f] || ""; });
      setFieldValues(data)
      setFieldValues(data);
    }
  }, []);

  const handleChange = (field, value) => {
    if (field == 'ssid_number') {
      validateSsidNumber(value);
    } else {
      setFieldValues({ ...fieldValues, [field]: value });
    }
  }

  const validateSsidNumber = (ssid_number) => {
    if (!ssid_number) {
      setFieldValues({ ...fieldValues, ssid_number: ssid_number });
      return;
    }
    var patt = /^[0-9]{6}[\+-A][0-9]{3}[0-9A-Y]$/
    var result = ssid_number.match(patt);
    if (result) {
      var checksum = parseInt(ssid_number.substring(0, 6) + ssid_number.substring(7, 10))
      var control_marks = '0123456789ABCDEFHJKLMNPRSTUVWXY'
      var i = checksum % 31
      result = control_marks[i] == ssid_number[10]
    }

    if (result && validateSsidNumber) {
      setIsValidSsid(true);
      var gender = ssid_number[9] % 2 == 0 ? 2 : 1
      var decade = { '-': "19", "A": "20" }
      var str = ssid_number.substring(0, 4) + decade[ssid_number[6]] + ssid_number.substring(4, 6)
      var mom = moment(str, "DDMMYYYY").format("DD.MM.YYYY")
      var birthdate = mom
      setFieldValues({ ...fieldValues, gender: gender, birthdate: birthdate, ssid_number: ssid_number })
    }
    else {
      setFieldValues({ ...fieldValues, ssid_number: ssid_number });
      setIsValidSsid(false);
    }
  }

  return (
    <div>
      <div className="detail-block">
        <div className='row'>

          <div className="col-12" >
            <h3 className="detail-header">{I18n.t("personal_information").toUpperCase()}</h3>
          </div>

          {fields.map(field => (
            <>
              <div className="col-md-4 col-12 bold">{I18n.t(field)}</div>
              <div className="col" style={{ display: 'flex' }}>
                {field == 'gender' ?
                  <>
                    <div className="field" style={{ display: "inline-flex" }} >
                      <label style={{ margin: "0 5px" }} > {I18n.t("T_male")} </label>
                      <input
                        style={{ margin: "0 5px" }}
                        value={1} type="radio"
                        name={`employee[${field}]`}
                        checked={fieldValues['gender'] == 1}
                        onChange={(e) => handleChange(field, e.target.value)}
                        disabled={isValidSsid}
                      />
                      <label style={{ margin: "0 5px" }} > {I18n.t("T_female")} </label>
                      <input
                        style={{ margin: "0 5px" }}
                        value={2} type="radio"
                        name={`employee[${field}]`}
                        checked={fieldValues['gender'] == 2}
                        onChange={(e) => handleChange(field, e.target.value)}
                        disabled={isValidSsid}
                      />
                    </div>
                  </>
                  :
                  <>
                    <input
                      key={`field_${field}`}
                      className='md'
                      type='text'
                      name={`employee[${field}]`}
                      value={fieldValues[field]}
                      onChange={e => handleChange(field, e.target.value)}
                      disabled={(isValidSsid || ssid_locked) && LOCKED_FIELDS[field] || (field == 'ssid_number' && ssid_locked)}
                    />
                    {(field == 'ssid_number' && !ssid_locked && fieldValues[field] && fieldValues[field].length > 0) &&
                      < Icon style={{ color: (isValidSsid ? 'green' : 'red'), position: 'absolute', left: '-15px', top: '5px' }} >{isValidSsid ? 'check' : 'clear'} </Icon>}
                  </>
                }
              </div>
              <div className="w-100" />
            </>
          ))}

          {custom_fields.map(field => (
            <>
              <div className="col-md-4 col-12 bold">{field.name}</div>
              <div className="col">
                <input className='md' type='text' name={`employee[${field.name}]`} defaultValue={employee_fields[field.id.toString()]} />
              </div>
              <div className="w-100" />
            </>
          ))}
        </div>
      </div >
    </div >
  )
}