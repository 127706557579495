import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { InputLabel, FormControl, Select, MenuItem } from '@material-ui/core';

import Theme from '@components/theme';


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  normalText: {
    transform: 'translate(0, -16px) scale(1) !important',
  },
  formControl: {
    //marginTop: 32,
    minWidth: 250,
  },
  select: {
    padding: 10,
  }
}));


export default function SelectWrapper(props) {
  const { options, multiple, label, name, checked, disabled } = props;

  const classes = useStyles();
  const [value, setValue] = React.useState(checked || []);

  const handleChange = event => {
    setValue(event.target.value);
  };

  return (
    <Theme>
      <FormControl variant='outlined' fullWidth className={classes.formControl}>
        <InputLabel shrink id={name} className={classes.normalText}>{label}</InputLabel>
        <Select
          id={name}
          multiple={multiple ? true : false}
          value={value}
          autoWidth={true}
          onChange={handleChange}
          inputProps={{ className: classes.select }}
          disabled={disabled ? true : false}
        >
          {options.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        {value.map((v, index) => <input key={"select_" + index} type="hidden" name={name} value={v} />)}

      </FormControl>
    </Theme>
  );

}
