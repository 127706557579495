import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['days', "salary", 'cost'];

  setDays() {
    this.daysTarget.value = this.daysTarget.value > 365 ? 365 : this.daysTarget.value < 1 ? 1 : this.daysTarget.value
    this.costTarget.value = Math.round(this.salaryTarget.value / (this.daysTarget.value / 12))
  }

  setSalary() {
    this.salaryTarget.value = Math.max(this.salaryTarget.value, 1);
    this.costTarget.value = Math.round(this.salaryTarget.value / (this.daysTarget.value / 12))
  }

  setCost() {
    this.costTarget.value = Math.max(this.costTarget.value, 1);
    this.salaryTarget.value = Math.round(this.costTarget.value * this.daysTarget.value / 12)
  }

  clamp(num, min, max) {
    return num <= min ? min : num >= max ? max : num;
  }

}