import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {LinearProgress} from '@material-ui/core';


const useStyles = makeStyles({
  wrapper: {
    flexGrow: 1,
    width: 150,
  },
  root: {
    height: 15,
    borderRadius: 20,
    backgroundColor: '#f1f1f1',
  },
  bar: {
    borderRadius: 20,
    backgroundColor: props => props.color,
  },
  label: {
    textAlign: props => props.align,
    width: '100%',
    display: 'block',
  }  
});

ProgressBar.defaultProps = {
  value: 0,
  label: '',
  labelAlign: 'center',
  color: '#79B611',
  paddingTop: 5,
}

export default function ProgressBar( props ) {
  const { value, label } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.wrapper}>
      <LinearProgress
        classes={{
          root: classes.root,
          bar: classes.bar,
        }}
        className={classes.margin}
        variant="determinate"
        color="secondary"
        value={value}
      />
      <span className={classes.label}>{label}</span>
    </div>
  );
}
