import React, { Fragment } from 'react';
import MuiDialog from "@material-ui/core/Dialog";
import Table from "@components/table";
import DialogActions from "@material-ui/core/DialogActions";

var selected = []

export default function BarDialog({ filters, questionnaires, questionnaireColumns, handleSubmit }) {
  const [open, setOpen] = React.useState(false);
  const [shownTable, setShownTable] = React.useState('filters')

  const filterColumns = [
    { Header: I18n.t('filter_name'), accessor: "name" },
    { Header: I18n.t('description'), accessor: 'description' },
  ]

  const handleClickOpen = () => {
    selected = [];
    setOpen(true);
  }

  const handleSave = () => {
    handleSubmit(selected);
    handleClose();
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleSelection = (rows, type) => {
    type = type[0];
    var ids = rows.map((row) => (type + row.original.id)) || [];
    var difference = ids.filter(x => !selected.includes(x)) || [];
    selected = selected.filter(x => !x.startsWith(type) || ids.includes(x)).concat(difference);
  }

  return (
    <Fragment>
      <button
        className={"btn btn-secondary"}
        onClick={handleClickOpen}
        style={{ float: 'none' }}
      >
        {I18n.t('add_bars')}
      </button>
      {open &&
        <MuiDialog
          fullWidth={true}
          maxWidth={'lg'}
          onClose={handleClose}
          open={open}
        >
          <div style={{ padding: "0 25px", height: "1200px" }}>
            <h1>{I18n.t('add_bars')}</h1>
            <p>{I18n.t('add_bars_info')}</p>

            <div>
              <button className={"tab " + (shownTable == 'filters' ? 'selected' : '')} style={{ width: "50%" }} onClick={() => setShownTable('filters')}>{I18n.t('filters')}</button>
              <button className={"tab " + (shownTable == 'questionnaires' ? 'selected' : '')} style={{ width: "50%" }} onClick={() => setShownTable('questionnaires')}>{I18n.t('questionnaires')}</button>
              <div style={{ display: (shownTable == 'filters' ? 'block' : 'none') }}>
                <Table
                  columnDefinitions={filterColumns}
                  rawData={filters}
                  useSelect={true}
                  useGlobalSearch={true}
                  setSelected={(rows) => handleSelection(rows, 'filter')}
                />
              </div>

              <div style={{ display: (shownTable == 'questionnaires' ? 'block' : 'none') }}>
                <Table
                  columnDefinitions={questionnaireColumns}
                  rawData={questionnaires}
                  useSelect={true}
                  useGlobalSearch={true}
                  setSelected={(rows) => handleSelection(rows, 'questionnaire')}
                />
              </div>
            </div>
          </div>

          <div>
            <DialogActions>
              <button onClick={handleClose} className="btn btn-default xl">
                {I18n.t("cancel").toUpperCase()}
              </button>
              <button
                onClick={handleSave}
                className="btn btn-primary xl"
              >
                {I18n.t("save").toUpperCase()}
              </button>
            </DialogActions>
          </div>
        </MuiDialog >
      }
    </Fragment >
  );
}
