import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import * as labels from 'chartjs-plugin-labels';

export default function DoughnutGraph(props) {

  return (
    <Doughnut
      data={props.info}
      width={1}
      height={1}
      options={{
        events: [],
        animation: false,
        plugins: {
          labels: {
            render: 'percentage',
            fontColor: 'black',
            fontSize: 16
          }
        }
      }}
    />
  );

}
