import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';

export default function ContentToTextBtn(props) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);

    // Programmatically focus and select the textarea
    setTimeout(() => {
      const textarea = document.querySelector('textarea');
      textarea.focus();
      textarea.select();
    }, 0);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCopy = async () => {
    const textarea = document.querySelector('textarea');
    textarea.select();

    try {
      await navigator.clipboard.writeText(textarea.value);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  return (
    <>
      <IconButton onClick={handleClickOpen}>
        <i className="material-icons">share</i>
      </IconButton>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth='xs'>
        <DialogContent dividers>
          <textarea
            defaultValue={props.content}
            rows={20}
            style={{ width: '100%', height: '200px', resize: 'none' }}
            readOnly
          />
        </DialogContent>
        <DialogActions>
          <button onClick={handleCopy} className="btn btn-primary md">
            {I18n.t("copy", { defaultValue: 'Kopioi leikepöydälle' })}
          </button>
          <button onClick={handleClose} className="btn btn-default sm">
            {I18n.t("cancel")}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
}