import React, { useEffect } from 'react';
import { Button, Grid } from '@material-ui/core';
import LoadingIndicator from '../../loading_indicator';

export default function QuestionnaireClosingPage(props) {

  useEffect(()=> {

  }, [])

  const findFirstPage = () => {
    for (var i = 0; i < props.pages.length; i++){
      if (props.pages[i].type == "QuestionnaireTemplatePage"){
        props.changePage(i + 1);
        return;
      }
    }
  }

  return (
    <Grid 
      container
      direction="column"
      justify="center"
      alignItems="center"
      style = {{margin: 40}}
    >    
      <h1 style={{paddingBottom: 50}}>{I18n.t('questionnaire_completed')}</h1>
      <p style={{paddingBottom: 100}}>{I18n.t('confirm_answers')}</p>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <button
          className="btn btn-default xl" 
          onClick={findFirstPage}
        >
          {I18n.t('questionnaire_edit')}        
        </button>
        <button 
          className="btn btn-primary xl"
          onClick={() => props.nextPage()}  >
          {I18n.t('questionnaire_done')}
        </button>
      </Grid>
    </Grid>
  )
}