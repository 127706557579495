import React, { useEffect } from 'react';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Textile from '../common/textile';

const useStyles = makeStyles(theme => ({
  border: {
    borderBottom: '1px solid #F1F1F1'
  },
  padding: {
    padding: 15,
    paddingLeft: 40,
    paddingRight: 40,
  }
}));

export default function QuestionnaireValueHorizontalRadio(props) {
  const { options, tkey, str_id, saveAnswer, borderless } = props;
  const classes = useStyles();
  const [value, setValue] = React.useState(props.answer === null ? "" : props.answer);

  const handleChange = (event) => {
    const newValue = parseInt(event.target.value, 10);
    setValue(newValue);
    saveAnswer(str_id, newValue);
  };

  useEffect(() => {
    setValue(props.answer === null ? "" : props.answer);
  }, [props]);

  return (
    <div className={`${!borderless ? classes.border : ''} ${classes.padding}`}>
      <FormControl component="fieldset">
        <FormLabel>
          <Textile text={I18n.t(tkey)} />
        </FormLabel>
        <RadioGroup row value={value} aria-label={str_id} name={str_id} onChange={handleChange}>
        <Grid container spacing={2}>
                {options.map((option, idx) => (
                <Grid item key={`${str_id}_gri_horizontal_${idx}`}>
                    <FormControlLabel
                    key={`${str_id}_option_horizontal_${idx}`}
                    value={option.value}
                    control={<Radio color="primary" />}
                    label={I18n.t(option.tkey)}
                    disabled={props.disabled}
                    labelPlacement="top"
                    />
                </Grid>
                ))}
            </Grid>
        </RadioGroup>

      </FormControl>
    </div>
  );
}
