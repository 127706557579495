import React, {useEffect} from 'react';

const MODES = ['view', 'edit'];

export default function inline_text_input(props){

  // Mode = ['View', 'Edit', 'Submitting']
  // Errors = []
  // Value = ''

  const [mode, setMode] = React.useState('view');
  const [errors, setErrors] = React.useState([]);
  const [value, setValue] = React.useState(props.value);
  const [actionUrl, setActionUrl] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const isFirstRun = React.useRef(true);
  const inputEl = React.useRef(null);
  const inputElConfirmation = React.useRef(null);

  const getUrl = () => props.action + '?' + 'remote=true';
  
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    const submit = async () => {
      let formData = new FormData();
      formData.append(`${props.scope}][${props.name}]`, inputEl.current.value);
      if (inputElConfirmation.current){
        formData.append(`${props.scope}][${props.name}_confirmation]`, inputElConfirmation.current.value);
      }

      const response = await fetch(getUrl(), {
        body: formData,
        method: props.method || 'post',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        },
      });
    //  const data = await response.json();
      if (response.status === 200) {
        if(props.type !== 'password')
          setValue(inputEl.current.value);
        setMode('view')
      }
    }


    submit();
  }, [actionUrl]);

  const handleEnterPress = (e) => {
    if (e.code == 'Enter') {
      inputEl.current.blur();
      e.preventDefault();
      setActionUrl(inputEl.current.value);
    }      
  }

  useEffect(() => {
    if (mode == MODES[1]){
      inputEl.current.focus();
      inputEl.current.addEventListener('keyup', handleEnterPress);  
    }

    return () => {
      inputEl.current && inputEl.current.removeEventListener('keyup', handleEnterPress);
    }
  }, [mode])

  return(
      <div className="inline-text-input">

        { mode == MODES[0] ? ( // View
            <div 
              className="readable"
              onClick={(e) => setMode('edit') }> 
                {value && value.length > 0 ? value : ( props.displayValue ? props.displayValue :
                  <span>+ {I18n.t('add')} {((props.translate === false) ? props.name : I18n.t(props.name)).toLowerCase()}</span>)
                }
            </div>
          ) : ( // Edit 
            <div>
              <input 
                ref={inputEl}
                type={props.type || "text"}
                name={props.name} 
                defaultValue={value}
                placeholder={props.placeholder}
                autoComplete="false"
              />
              { props.confirmation && (
                <input 
                  ref={inputElConfirmation}
                  type={props.type || "text"}
                  name={props.name + '_confirmation'} 
                  defaultValue=""
                  autoComplete="false"
                  placeholder={I18n.t(props.name + '_confirmation')}
                />
              )}
              <button className="btn btn-secondary" onClick={() => setActionUrl(inputEl.current.value)}>{I18n.t('save')}</button>
              <button className="btn btn-default" onClick={() => setMode('view')}>{I18n.t('cancel')}</button>
            </div>
        )}
      </div>
  )
}