import React from "react";
import { InputLabel, OutlinedInput, InputAdornment } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Base from "./base";

import Textile from "../common/textile";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  border: {
    borderBottom: "1px solid #F1F1F1",
    padding: 15,
    paddingLeft: 40,
    paddingRight: 40,
  },
  margin: {
    marginBottom: 10,
    marginTop: 20,
  },
  normalText: {
    transform: "translate(0, 0) scale(1)",
  },
  textField: {
    width: 275,
  },
}));

export default function QuestionnaireValueShortText({inputType, ...props}) {
  const classes = useStyles();
  const { tkey, str_id, textFieldProps, saveAnswer, answer, unit, max_val } = props;
  const identifier = `input-${str_id}`;
  const maxVal = max_val || undefined
  const minVal = 0
  
  const [value, setValue] = React.useState(answer || "");

  const handleChange = (event) => {
    setValue(event.target.value);

    if (inputType === "number"){
      let inputValue = parseInt(event.target.value, 10);
      // Check if the input value is  outside the range
      if (!isNaN(inputValue) && (inputValue < minVal || inputValue > maxVal)) {
        // Adjust the value to the closest within the range
        inputValue = Math.min(Math.max(inputValue, minVal), maxVal);
      }

      // Update the state with the adjusted value
      setValue(inputValue.toString());
    }

  };

  return (
    <Base className={classes.border} {...props}>
      <InputLabel shrink htmlFor={identifier} className={classes.normalText}>
        <Textile
          text={props.translate == false ? props[props.language] : I18n.t(tkey)}
        />
      </InputLabel>
      <OutlinedInput
        id={identifier}
        className={classes.textField}
        value={value}
        margin="dense"
        endAdornment={
          unit ? (
            <InputAdornment position="end">{I18n.t(props.unit)}</InputAdornment>
          ) : null
        }
        variant="outlined"
        onChange={handleChange}
        inputProps={{
          maxLength: props.maxLength || 64,
          type: inputType || "text",
          // this max min only limit the arrow function of the input
          min: inputType === "number" ? minVal : undefined,
          max: inputType === "number" ? maxVal : undefined,
        }}
        onBlur={(e) => {
          saveAnswer(str_id, event.target.value);
        }}
        disabled={props.disabled}  
        {...textFieldProps}
      />
    </Base>
  );
}
