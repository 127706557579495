import React from 'react';
import TextField from '@material-ui/core/TextField';
import MUIAutocomplete from '@material-ui/lab/Autocomplete';

export default function Autocomplete(props) {
  const [value, setValue] = React.useState(props.value);
  const [first, setFirst] = React.useState(true);
  const { options } = props

  return (
    <div>
      <MUIAutocomplete
        freeSolo
        disableClearable
        inputValue={value}
        options={options.map((option) => option.value)}
        onInputChange={(_, newInputValue) => {
          if (!first) {
            setValue(newInputValue);
            props.onChange(newInputValue);
          } else {
            setFirst(false);
          }
        }}
        renderInput={(params) => (
          <TextField {...params} />
        )}
      />
    </div>
  );
}