import React from "react";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#92d566',
      main: '#61a337',
      dark: '#2f7400',
      contrastText: '#fff',
    },
    secondary: {
      light: '#3f5c94',
      main: '#003366',
      dark: '#000c3b',
      contrastText: '#fff',
    },
  }
});


export default function Theme(props) {
  return (
    <ThemeProvider theme={theme}>
      {props.children}
    </ThemeProvider>
  )
}