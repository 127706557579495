import React from 'react';
import { FormControl, InputLabel, InputBase, MenuItem, TextField, Select } from '@material-ui/core';
import {
  fade,
  withStyles,
  makeStyles,
} from '@material-ui/core/styles';

const BootstrapSelect = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  select: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 14,
    padding: '10px 12px',
    minWidth: 250,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      'Open Sans',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}))(Select);

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  border: {
    borderBottom: '1px solid #F1F1F1',
    padding: 15,
    paddingLeft: 40,
    paddingRight: 40,
  },
  margin: {
    marginBottom: 20,
    marginTop: 20
  },
  normalText: {
    transform: 'translate(0, 1.5px) scale(1) !important;',
    top: -20
  }
}));

export default function QuestionnaireValueSelect(props) {
  const classes = useStyles();
  const { tkey, str_id, options, saveAnswer, answer } = props;
  const [value, setValue] = React.useState(answer || "");

  const identifier = `input-${str_id}`;

  const handleChange = event => {
    setValue(event.target.value);
    saveAnswer(str_id, event.target.value);
  };

  return (
    <div className={classes.border}>
      <FormControl variant="outlined" className={classes.margin} disabled={props.disabled}>
        <InputLabel shrink htmlFor={identifier} className={classes.normalText}>
          {I18n.t(tkey)}
        </InputLabel>
        <BootstrapSelect
          id={identifier}
          value={value}
          onChange={handleChange}
        >
        {options.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {I18n.t(option.tkey)}
            </MenuItem>
          ))}
        </BootstrapSelect>
      </FormControl>
    </div>
  )
}