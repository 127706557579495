import React from 'react';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';

export default function TagSelector(props) {
  const [tags, setTags] = React.useState(props.tags); 
  const [options] = React.useState(props.options.sort());
  const [text, setText] = React.useState("");
  const [focus, setFocus] = React.useState(false);

  const handleRemove = (item) => {
    var arr = tags.filter(function(i) {
      return i !== item
    }).sort();
    setTags(arr);
    if (props.setTags) {
      props.setTags(arr);
    }
  }

  const handleChange = (e) => {
    setText(e.target.value);
  }

  const handleTagClick = (item) => {
    
  }

  const handleText = (e, txt) => {
    if (e) { 
      e.preventDefault();
      e.stopPropagation();
    }

    if (txt !== "") {
      var found = false;
      tags.forEach(item => {
        if (item === txt) {
          found = true;
        }
      });
      if (!found) {
        options.forEach(item => {
          if (item === txt) {
            found = true;
          }
        });
        if (found) {
          var arr = [...tags, txt].sort();
          setTags(arr);
          if (props.setTags) {
            props.setTags(arr);
          }
        }
        else if (props.allowInput) {
          var arr = [...tags, txt].sort();
          setTags(arr);
        }
      }
      setText("");
    }
  }

  const handleNewTag = (e) => {
    setText(e.target.value);
    if (e.key == 'Enter') {
      handleText(e, e.target.value)
      e.target.value = "";
    }
  }

  const match = (txt) => {
    var text1 = txt.toLowerCase();
    var text2 = text.toLowerCase();
    return text1.indexOf(text2) === -1;
  }

  const handleBlur = (e) => {
    const timer = setTimeout(() => {
      setFocus(false);
      if (text !== "") {
        setText("");
      }
    }, 150);
  }

  return (
    <div id="tag-selector">
      <div className="box">
        {tags.map((item, index) => (
          <div className="tag" key={"tag_"+item} onClick={(e) => {handleTagClick(item)}}>
            <div className="text">
              {item}
            </div>
            <Button className="remove-button" onClick={(e) => handleRemove(item)} style={{padding: 0}}>
              <Icon style={{marginRight: "3px"}}>close</Icon>
            </Button>
            <input name={props.group + "[tags][" + index +"]"} type={"text"} value={item} style={{ display: 'none' }} onChange={e => { }}/>
          </div>
        ))}
      </div>
      <input
        placeholder={props.hintText ? props.hintText : I18n.t("add_tag_placeholder")}
        style={{width: "200px"}}
        autoComplete="on"
        type="search"
        value={text}
        onChange={e => handleChange(e)}
        onKeyDown={e => handleNewTag(e)}
        onFocus={e => setFocus(true)}
        onBlur={e => handleBlur(e)}
      />
      <div className={"suggestion-container " + ((focus && options.length > 0) ? 'show' : '')} style={{ width: "200px", position: "absolute", overflowY: 'auto', zIndex: 1000, maxHeight: 350}}>
        {options.filter(n => !tags.includes(n)).map((item, index) => (
          !focus || match(item) ? null :
            <button id={"tag_"+item} type="text" className="suggestion" key={'option_'+item}  onClick={e => handleText(e, item)} style={{textAlign: 'left', zIndex: 1001}}>
              {item}
            </button>
        ))}
      </div>
    </div>
  );
}