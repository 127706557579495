import { Controller } from "stimulus"


export default class extends Controller {
  static targets = ['element', 'container'];

  add(){
    var index = this.containerTarget.getElementsByTagName("input").length / 2;

    var e = this.elementTarget.cloneNode(true);
    e.style.display = this.containerTarget.style.display;
    this.containerTarget.appendChild(e);
    var newInputs = e.getElementsByTagName("input");

    for(var i = 0; i < newInputs.length; i++) {
      var name = newInputs[i].name.replace('new-0', 'new-' + index)
      newInputs[i].setAttribute("name", name);
    }
  }

}