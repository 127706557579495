import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import OdumLogo from "images/odum_logo_big.png";

const useStyles = makeStyles({
  logo: {
    width: 120,
    marginTop: -60,
    marginBottom: 20,
  },
});

export default function QuestionnaireThankyouPage(props) {
  const { type } = props;
  const classes = useStyles();

  return (
    <Grid container direction="column" justify="center" alignItems="center">
      <div style={{ padding: "50px 20px" }}>
        <h1 style={{ textAlign: "center" }}>{I18n.t(`T_${type}_thank_you`)}</h1>
        {props.calculations.includes("hvvk") && (
          <div>
            <p className="center">{I18n.t("hvvk_thank_you_details")}</p>
            <p className="center" style={{ marginTop: 10 }}>
              {I18n.t("hvvk_thank_you_info")}
            </p>
            <div
              style={{
                width: "90%",
                maxWidth: "500px",
                margin: "auto",
                display: "flex",
                marginTop: "20px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <a
                style={{ minWidth: "200px", whiteSpace: "nowrap" }}
                href={props.exitLink}
                className="btn btn-default xl center"
              >
                {I18n.t("exit").toUpperCase()}
              </a>
              <a
                style={{ minWidth: "200px", whiteSpace: "nowrap" }}
                href={props.returnLink}
                className="btn btn-primary xl center"
              >
                {I18n.t("return_to_questionnaires").toUpperCase()}
              </a>
            </div>
          </div>
        )}
      </div>
    </Grid>
  );
}
