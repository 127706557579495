import React, { useEffect, Fragment } from "react";
import { useTable, useSortBy, useRowSelect, usePagination } from "react-table";
import { TablePagination } from "@material-ui/core";
import moment from "moment";
import ActionButton from "@components/action_button";
import matchSorter from "match-sorter";

Table.defaultProps = {
  columnDefinitions: [],
  rawData: [],
  rowPath: null,
  useGlobalSearch: true, // Should we display global search on top of table
};

export default function Table(props) {
  const {
    columnDefinitions,
    rowPath,
    useGlobalSearch,
    useSelect,
    onSelect,
  } = props;
  const [rawData, setRawData] = React.useState(props.rawData);
  const [globalSearch, setGlobalSearch] = React.useState("");
  
  if (rawData != props.rawData) {
    setRawData(props.rawData);
  }
  // Join some default column definitions (action buttons)
  let columnDefs = [...columnDefinitions];

  if (useSelect) {
    columnDefs.unshift({
      id: "selection",
      Header: ({ getToggleAllRowsSelectedProps }) => (
        <div>
          <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
        </div>
      ),
      Cell: ({ row }) => (
        <div>
          <input type="checkbox" {...row.getToggleRowSelectedProps()} />
        </div>
      ),
    });
  }

  if (!props.skipColumDefs) {
    let index = columnDefs.findIndex((column) => column.accessor === "actions");
    if (index > 0) {
      columnDefs[index].Cell = ({ cell: { value } }) => (
        <ActionButton actions={value} key={value} />
      );
      columnDefs[index].width = 30;
    }

    index = columnDefs.findIndex((column) => column.accessor === "gender");
    if (index > 0) {
      columnDefs[index].Cell = ({ cell: { value } }) =>
        value == 1 || value == "m" ? (
          <Fragment>{I18n.t("T_male")}</Fragment>
        ) : value == 2 || value == "f" ? (
          <Fragment>{I18n.t("T_female")}</Fragment>
        ) : (
              <Fragment />
            );
    }

    index = columnDefs.findIndex(
      (column) =>
        column.type === "date" ||
        (column.accessor && column.accessor.endsWith("date"))
    );
    if (index > 0) {
      columnDefs[index].Cell = ({ cell: { value } }) => {
        let date =
          value && value.length > 0
            ? moment(value, "YYYY-MM-DD").format("DD.MM.YYYY")
            : null;
        return (
          <Fragment>
            <span style={{ display: "none" }}>
              {date ? date.valueOf() : null}
            </span>
            <Fragment>{date ? date.toString() : null}</Fragment>
          </Fragment>
        );
      };
    }
  
    index = columnDefs.findIndex((column) =>
      column.accessor ? column.accessor.startsWith("link_") : -1
    );
    if (index > 0) {
      columnDefs[index].Cell = ({ cell: { value } }) =>
        value.map((v) => (
          <a className="btn" style={{ borderRadius: 10 }} href={v[1]}>
            {" "}
            {v[0]}{" "}
          </a>
        ));
      columnDefs[index].width = 30;
    }
  }

  const columns = React.useMemo(() => columnDefs, []);

  const newFilteredData =
    rawData && rawData.length > 0
      ? matchSorter(rawData, globalSearch, {
        keys: rawData ? Object.keys(rawData[0]) : [],
      })
      : [];
  const data = React.useMemo(() => newFilteredData, [globalSearch, rawData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    prepareRow,
    selectedFlatRows,
    state: { selectedRowPaths, pageIndex, pageSize },
    toggleRowSelectedAll
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 20 },
    },
    useSortBy,
    useRowSelect,
    usePagination
  );

  if (props.setSelected) {
    props.setSelected(selectedFlatRows);
  }

  useEffect(() => {
    if (typeof onSelect === "function") {
      onSelect(selectedFlatRows.map((r) => r.original));
    }
  }, [selectedRowPaths]);

  useEffect(() => {
    if (props.selectAll) {
      toggleRowSelectedAll()
    }
  }, [props.selectAll, props.rawData])

  const handleGlobalSearch = (e) => {
    const searchStr = e.target.value;
    setGlobalSearch(searchStr);
  };

  const handleRowClick = (row) => {
    if (rowPath) {
      Turbolinks.visit(
        rowPath.replace("__id__", row.original[props.row_identifier || "id"])
      );
    } else if (useSelect) {
      row.toggleRowSelected();
    }
  };

  return (
    <div>
      {useGlobalSearch && rawData.length > 10 && (
        <div className="global-table-search">
          <input
            size="large"
            name="globalSearch"
            value={globalSearch || ""}
            onChange={handleGlobalSearch}
            placeholder={I18n.t("T_search")}
            autoComplete="off"
          />
          <i className="material-icons">search</i>
        </div>
      )}
      <div className="ohs-table">
        <table {...getTableProps({ className: "ohs-table" })}>
          <thead style={{ position: "sticky", top: 0, zIndex: 1 }}>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    <span {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {column.isSorted &&
                        (column.isSortedDesc ? (
                          <span className=" arrow down" />
                        ) : (
                            <span className=" arrow up" />
                          ))}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map(
              (row, i) =>
                prepareRow(row) || (
                  <tr
                    {...row.getRowProps()}
                    onClick={() => handleRowClick(row)}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                )
            )}
          </tbody>
        </table>
      </div>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 20, 30, 50]}
        component="div"
        count={rows.length}
        rowsPerPage={pageSize}
        page={pageIndex}
        backIconButtonProps={{
          "aria-label": "previous page",
        }}
        nextIconButtonProps={{
          "aria-label": "next page",
        }}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to === -1 ? count : to} / ${count}`
        }
        labelRowsPerPage={I18n.t("rows_per_page")}
        onChangePage={(e, page) => {
          gotoPage(page);
        }}
        onChangeRowsPerPage={(e) => {
          setPageSize(Number(e.target.value));
        }}
      />
    </div>
  );
}
