import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';

import OdumLogo from 'images/odum_logo_big.png';


const useStyles = makeStyles({
  logo: {
      width: 120,
      marginTop: -60,
      marginBottom: 20,
  },
});


export default function QuestionnaireWelcomePage(props) {
  const classes = useStyles();


  const handleLanguageSelection = (e) => {
    e.preventDefault(); e.stopPropagation();

    props.setLanguage(e.target.getAttribute('data-value'));
    props.nextPage();
  }

  return (
    <Grid 
      container
      direction="column"
      justify="center"
      alignItems="center"
      style={{ marginTop: 30}}
    >    
      {props.availableLanguages.map((language) => (
        <Grid item xs={12} key={`welcome-title-${language}`}>
          <h1>{I18n.t('T_enquiry_welcome_title', {locale: language})}</h1>
        </Grid>
        )
      )}

      <Grid 
        container
        direction="row"
        justify="center"
        alignItems="center"
        style={{padding: 30}}

      >
        {props.availableLanguages.map((language) => (
          <Grid item key={`language-select-${language}`} style={{padding: 10}}>
            <button 
              className="btn btn-default xl"
              onClick={handleLanguageSelection}
              data-value={language}
            >
              {I18n.t('T_language_button_text', {locale: language})}
            </button>
          </Grid>
        ))}
      </Grid>

    </Grid>
  )
}