import React from "react";
import { InputLabel, OutlinedInput } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Theme from "@components/theme";
import AuthenticityToken from "@components/authenticity_token";

const useStyles = makeStyles((theme) => ({
  normalText: {
    transform: "translate(0, 0) scale(1)",
  },
}));

function validateEmail(email) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

// Remove whitespace and downcase
function formatEmail(email) {
  const formatted = email;
  return formatted.length > 0 ? formatted.toLowerCase() : null;
}

export default function ParticipantResolve(props) {
  const classes = useStyles();

  const [value, setValue] = React.useState(props.emails || "");

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <Theme>
      <InputLabel shrink htmlFor="email-input" className={classes.normalText}>
        {I18n.t("emails_label")}
      </InputLabel>
      {props.errormessage && <p>{props.errormessage}</p>}
      <OutlinedInput
        id="email-input"
        value={value}
        margin="dense"
        variant="outlined"
        onChange={handleChange}
        multiline={true}
        fullWidth={true}
        rows={4}
        autoFocus
      />
      <form method="POST">
        <AuthenticityToken />
        {value.length > 0 && (
          <InputLabel
            shrink
            htmlFor="email-input"
            className={classes.normalText}
          >
            Lisättävät
          </InputLabel>
        )}
        {value.length > 0 &&
          value.split(/,|\n|\s/).map((v, idx) => {
            const formattedValue = formatEmail(v);
            if (formattedValue !== null) {
              return (
                <OutlinedInput
                  key={`email-input-${idx}`}
                  error={!validateEmail(formattedValue)}
                  margin="dense"
                  style={{ marginTop: "1rem" }}
                  fullWidth={true}
                  name="participant_email[]"
                  type="text"
                  readOnly={true}
                  value={formattedValue}
                />
              );
            }
            return null;
          })}
      </form>
    </Theme>
  );
}
