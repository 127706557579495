import React, { useEffect } from "react";
import { defaultPostHeaders } from "@helpers/index";

import {
  Button,
  Checkbox,
  Select,
  MenuItem,
  Icon,
  Tooltip,
} from "@material-ui/core/";
import TextField from "@material-ui/core/TextField";
import update from "immutability-helper";
import Question from "@views/questionnaire/question";
import LoadingIndicator from "@components/loading_indicator";

const QuestionMemo = React.memo(Question, (props, nextProps) => {
  props.str_id == nextProps.str_id;
});

const COMPONENTS = {
  QuestionnaireValueShortText: { value: 0, label: "ShortText" },
  QuestionnaireValueLongText: { value: 1, label: "LongText" },
  QuestionnaireValueSelect: { value: 2, label: "Select" },
  QuestionnaireValueRadio: { value: 3, label: "Radio" },
  QuestionnaireValueRadioFloat: { value: 4, label: "RadioFloat" },
  QuestionnaireValueDate: { value: 5, label: "Date" },
  QuestionnaireCategoryTitle: { value: 6, label: "Title" },
  QuestionnaireTextBlock: { value: 7, label: "TextBlock" },
  QuestionnaireValueInteger: { value: 8, label: "Integer" },
  QuestionnaireConditionChanger: { value: 9, label: "Condition" },
  QuestionnaireValueHorizontalRadio: { value: 10, label: "HorizontalRadio" },
};

const CALCULATIONS = ["hvvk", "tyse"];

export default function QuestionnaireEditor(props) {
  const [template, setTemplate] = React.useState(props.template);
  const [identifier, setIdentifier] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [result, setResult] = React.useState();
  const [currentQuestion, setCurrentQuestion] = React.useState();

  useEffect(() => {
    var questions = sortQuestions(template.questions.questions);
    var last = [0, 0];
    for (var i = 0; i < questions.length; i++) {
      var now = [questions[i].questionnaire_page, questions[i].sort_order];
      if (last[0] == now[0]) {
        questions[i].sort_order = last[1] + 1;
      } else if (last[0] < now[0]) {
        questions[i].sort_order = 1;
      }
      questions[i].id = i;
      last = [questions[i].questionnaire_page, questions[i].sort_order];
    }
    var result = update(template, {
      questions: { questions: { $set: questions } },
    });
    setTemplate(result);
    setIdentifier(questions.length);
    setLoading(false);
  }, []);

  const handleSubmit = () => {
    var temp = template;

    // Build pages
    var lastPage = 0;
    temp.questions.questions.forEach((q) => {
      if (q.questionnaire_page > lastPage) {
        lastPage = q.questionnaire_page;
      }
    });
    var pages = [];
    pages.push({ sort_order: 1, type: "QuestionnaireWelcomePage" });
    pages.push({ sort_order: 2, type: "QuestionnaireInformationPage" });
    for (var i = 3; i <= lastPage; i++) {
      pages.push({ sort_order: i, type: "QuestionnaireTemplatePage" });
    }

    pages.push({ sort_order: lastPage + 1, type: "QuestionnaireClosingPage" });
    pages.push({ sort_order: lastPage + 2, type: "QuestionnaireThankyouPage" });
    temp.questions.pages = pages;

    // Delete unnecessary data
    temp.questions.questions.forEach((q) => {
      delete q.id;
      delete q.calculated;
      delete q.modifiable;
      if (q.mandatory == false) {
        delete q.mandatory;
      }
      if (q.color_report == false) {
        var delete_color = true
        delete q.color_report;
      }
      if (q.options) {
        if (q.options.length == 0) {
          delete q.options;
        } else {
          q.options.forEach((o) => {
            delete o.type;
            delete o.value_type;
            delete o.effect;
            if (delete_color) {
              delete o.color;
            }
          });
        }
      }
    });
    setResult(JSON.parse(JSON.stringify(temp)));
  };

  const sortQuestions = (questions) => {
    return questions.sort((a, b) => {
      return (
        a.questionnaire_page * 100 +
        a.sort_order -
        (b.questionnaire_page * 100 + b.sort_order)
      );
    });
  };

  const handleQuestionValue = React.useCallback(
    (index, context, value) => {
      if (context == "type") {
        Object.keys(COMPONENTS).map((key) => {
          if (COMPONENTS[key].value == value) {
            value = key;
          }
        });
      }
      var result = update(template, {
        questions: { questions: { [index]: { [context]: { $set: value } } } },
      });
      setTemplate(result);
      setCurrentQuestion(template.questions.questions);
    },
    [template]
  );

  const handleOptions = React.useCallback(
    (questionIndex, action, options) => {
      var result;
      if (action == "add") {
        if (options) {
          result = update(template, {
            questions: {
              questions: {
                [questionIndex]: {
                  options: {
                    $push: [{ tkey: "", value: options.length + 1 }],
                  },
                },
              },
            },
          });
        } else {
          result = update(template, {
            questions: {
              questions: {
                [questionIndex]: {
                  options: {
                    $set: [{ tkey: "", value: 1 }],
                  },
                },
              },
            },
          });
        }
      } else {
        if (options.length > 0) {
          result = update(template, {
            questions: {
              questions: {
                [questionIndex]: {
                  options: {
                    $splice: [[options.length - 1, 1]],
                  },
                },
              },
            },
          });
        } else {
          result = update(template, {
            questions: {
              questions: {
                [questionIndex]: {
                  options: {
                    $set: undefined,
                  },
                },
              },
            },
          });
        }
      }
      setTemplate(result);
    },
    [template]
  );

  const handleOptionValue = React.useCallback(
    (questionIndex, optionIndex, context, value) => {
      var result = update(template, {
        questions: {
          questions: {
            [questionIndex]: {
              options: { [optionIndex]: { [context]: { $set: value } } },
            },
          },
        },
      });
      setTemplate(result);
    },
    [template]
  );

  const handleQuestion = React.useCallback(
    (action, page, index) => {
      var questions = template.questions.questions;
      if (action == "add") {
        questions.forEach((q) => {
          if (q.questionnaire_page == page && q.sort_order >= index) {
            q.sort_order += 1;
          }
        });
        questions.push({
          tkey: "",
          questionnaire_page: page,
          sort_order: index,
          type: "QuestionnaireValueShortText",
          id: identifier,
        });
        sortQuestions(questions);
        setIdentifier(identifier + 1);
      } else if (action == "remove") {
        var i = questions.findIndex((item) => {
          return item.questionnaire_page == page && item.sort_order == index;
        });
        questions.splice(i, 1);
        questions.forEach((q) => {
          if (q.questionnaire_page == page && q.sort_order > index) {
            q.sort_order -= 1;
          }
        });
      } else if ((action = "up")) {
        questions.forEach((q) => {
          if (q.questionnaire_page == page) {
            if (q.sort_order == index - 1) {
              q.sort_order += 1;
            } else if (q.sort_order == index) {
              q.sort_order -= 1;
            }
          }
        });
      } else if ((action = "down")) {
        questions.forEach((q) => {
          if (q.questionnaire_page == page) {
            if (q.sort_order == index + 1) {
              q.sort_order -= 1;
            } else if (q.sort_order == index) {
              q.sort_order += 1;
            }
          }
        });
      }
      template.questions.questions = sortQuestions(questions);
      setTemplate(JSON.parse(JSON.stringify(template)));
    },
    [template]
  );

  const handlePage = React.useCallback(
    (question, action) => {
      var questions = template.questions.questions;
      if (action == "add") {
        var page = question.questionnaire_page;
        var index = question.sort_order;
        var lastIndex = 0;

        questions.forEach((q) => {
          if (q.questionnaire_page == page - 1 && q.sort_order > lastIndex) {
            lastIndex = q.sort_order;
          } else if (q.questionnaire_page == page) {
            if (q.sort_order >= index) {
              q.questionnaire_page += 1;
              q.sort_order = q.sort_order - index + 1;
            }
          } else if (q.questionnaire_page > page) {
            q.questionnaire_page += 1;
          }
        });
      } else if (action == "remove") {
        var page = question;
        var lastIndex = 0;
        questions.forEach((q) => {
          if (q.questionnaire_page == page) {
            q.sort_order = lastIndex + q.sort_order;
            q.questionnaire_page -= 1;
          } else if (q.questionnaire_page > page) {
            q.questionnaire_page -= 1;
          } else if (
            q.questionnaire_page == page - 1 &&
            q.sort_order > lastIndex
          ) {
            lastIndex = q.sort_order;
          }
        });
      }
      setTemplate(JSON.parse(JSON.stringify(template)));
    },
    [currentQuestion]
  );

  return (
    <div>
      {loading ? (
        <LoadingIndicator />
      ) : (
          <div>
            <h1>EDIT QUESTIONNAIRE</h1>
            <div>{"Name: " + template.str_id}</div>
            <div>{"Anonymous: " + template.anonymous}</div>
            <div>{"Authentication: " + template.strong_authentication}</div>
            <TextField
              value={template.languages}
              onChange={(e) =>
                handleChange("language", e.target.value, undefined)
              }
            />
            <h1>QUESTIONS</h1>
            <Button
              className="edit_add"
              onClick={(e) => handleQuestion("add", 1, 1)}
            >
              {"+ Add new question"}
            </Button>
            <div>
              <p>{JSON.stringify(template.questions.pages)}</p>
              {template.questions.pages.map((questionnaire_page, index) => {
                return (
                  <div>
                    <h1 style={{ display: "inline" }}>
                      {questionnaire_page.type}
                    </h1>
                    {index > 1 && (
                      <Tooltip title="Remove pagebreak">
                        <Button onClick={(e) => handlePage(index, "remove")}>
                          <Icon>backspace</Icon>
                        </Button>
                      </Tooltip>
                    )}
                    <hr />
                    {template.questions.questions.map((question, index) => {
                      if (
                        questionnaire_page.sort_order !=
                        question.questionnaire_page
                      ) {
                        return <></>;
                      }
                      return (
                        <div key={`question_container_${question.id}`}>
                          <QuestionMemo
                            question={question}
                            index={index}
                            handlePage={handlePage}
                            handleQuestion={handleQuestion}
                            handleQuestionValue={handleQuestionValue}
                            handleOptions={handleOptions}
                            handleOptionValue={handleOptionValue}
                            components={COMPONENTS}
                          />
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
            <Button onClick={() => handleSubmit()}>Submit</Button>
            {result && <>{JSON.stringify(result.questions)}</>}
          </div>
        )}
    </div>
  );
}
