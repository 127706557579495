import React from "react";
import QuestionnaireValueShortText from "./questionnaire_value_short_text";

export default function QuestionnaireValueLongText(props) {
  return (
    <QuestionnaireValueShortText
      {...props}
      key={props.str_id}
      textFieldProps={{
        multiline: true,
        fullWidth: true,
        rows: "4",
        className: "",
      }}
      maxLength={2048}
    />
  );
}
