import { Controller } from "stimulus"
import LoadingIndicator from '../components/loading_indicator';
import React from 'react';
import ReactDOM from 'react-dom';

export default class extends Controller {

  fetchContent = async (path) => {
    const response = await fetch(path);
    const html = await response.text();

    return html;
  }

  resetWidgets = () => {
    ReactDOM.render(<LoadingIndicator alignCenter={true}/>, this.element);
  }

  initialize () {
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
    // TODO: Breaks if query params are present
      this.fetchContent(this.data.get('path') + '?no_layout=false').then(html => {
        this.element.innerHTML = html;
      });
    }

    // Cache only the loading indicator to avoid flashing when cache version is shown
    document.addEventListener('turbolinks:before-cache', this.resetWidgets);
  }

  connect() {
    document.addEventListener('turbolinks:before-cache', this.resetWidgets);
  }

  disconnect() {
    document.removeEventListener('turbolinks:before-cache', this.resetWidgets);
  }

}

