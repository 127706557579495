import React, { Fragment } from 'react';
import MuiDialog from "@material-ui/core/Dialog";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import LoadingIndicator from "@components/loading_indicator";
import { defaultPostHeaders } from "@helpers/index";
import Autocomplete from "@components/autocomplete"

export default function ReportFilter(props) {
  const [options] = React.useState(props.filterData.options);
  const [operators] = React.useState(props.filterData.operators);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [modified, setModified] = React.useState(false);
  const [response, setResponse] = React.useState();
  const [filters, setFilters] = React.useState([]);
  const [defaultFilters, setDefaultFilters] = React.useState(props.filterData.filter_list);
  const [selected, setSelected] = React.useState();
  const [deleted, setDeleted] = React.useState();
  const [editedFilters, setEditedFilters] = React.useState([])

  const handleClickOpen = () => {
    setFilters(defaultFilters.concat());
    setSelected(defaultFilters.length > 0 ? defaultFilters[0] : null);
    setOpen(true);
    setModified(false);
    setResponse('');
    setDeleted([]);
  }

  const handleClose = () => {
    setFilters(defaultFilters);
    setOpen(false);
  }

  const handleSave = () => {
    if (modified) {
      setLoading(true);
      fetch(
        props.submitPath,
        {
          body: JSON.stringify({
            filters: filters.filter(f => f.id < 0 || deleted.includes(f.id) || editedFilters.includes(f.id)),
            deleted: deleted
          }),
          method: "post",
          headers: defaultPostHeaders(),
        }
      ).then((response) => {
        if (response.status == 500) {
          setResponse([I18n.t("T_internal_error")]);
          setLoading(false);
          return;
        }
        response
          .json()
          .then((json) => {
            if (!json.success) {
              setResponse(json.message);
              setLoading(false);
            }
            else {
              setFilters(json.filters);
              setDefaultFilters(json.filters);
              props.handleSave(json.filters);
              setLoading(false);
              setOpen(false);
            }
          })
          .catch((error) => {
            setResponse(error);
            setLoading(false);
          });
      });
    } else {
      setOpen(false);
    }

  }

  const selectFilter = (filter, index) => {
    setSelected(JSON.parse(JSON.stringify(filter)));
  }

  const newFilter = () => {
    var data = filters;
    var keys = Object.keys(options)
    var newFilter = {
      name: 'new filter',
      description: '',
      id: -(filters.length + 1),
      filters: {}
    }
    keys.forEach((key) => {
      newFilter.filters[key] = { operator: operators[0], filters: [] };
    });
    data.push(newFilter);
    setFilters(data);
    selectFilter(newFilter, data.length - 1);
    setModified(true);
  }

  const handleChange = (category, context, value, index = undefined) => {
    var data = JSON.parse(JSON.stringify(selected));

    if (context == 'filter') {
      data.filters[value.index].key = value.value;
      data.filters[value.index].value = options[value.value][0].value;
      context = 'value';
      value.value = options[value.value][0].value;
    }
    if (context == 'value') {
      data.filters[category].filters[value.index].value = value.value;
      var subOptions = options[category]
      subOptions.forEach((option) => {

        if (option.value == value.value) {
          data.filters[category].filters[value.index].type = option.type;
          if (option.type == 'range') {
            data.filters[category].filters[value.index].detail = { value: { min: undefined, max: undefined } }
          }
          else {
            if (option.options && option.type == 'select') {
              data.filters[category].filters[value.index].detail = option.options[0].value
            }
            else {
              data.filters[category].filters[value.index].detail = ''
            }
          }
        }
      });
    }
    else if (context == 'add') {
      var key = Object.keys(options[category])[0];
      data.filters[category].filters.push(
        {
          value: options[category][key].value,
          detail: (options[category][key].options && options[category][key].type == 'select') ? options[category][key].options[0].value : '',
          type: options[category][key].type
        });
    }
    else if (context == 'remove') {
      data.filters[category].filters.splice(-1, 1)
    }
    else if (context == 'detail') {
      if (data.filters[category].filters[value.index].detail == value.value) {
        return;
      }
      data.filters[category].filters[value.index].detail = value.value;
    }
    else if (context == 'operator') {
      data.filters[category].operator = value;
    }
    else {
      data[context] = value;
    }
    setSelected(data);
    if (selected && index == undefined) {
      index = filters.findIndex((f) => selected.id == f.id);
    }
    if (index != undefined) {
      var arr = filters
      arr[index] = data;
      setFilters(arr.slice());
    } else {
      index = filters.find((f) => f.id == data.id)
      var arr = filters
      arr[index] = data;
      setFilters(arr.slice());
    }

    if (!editedFilters.includes(data.id)) {
      setEditedFilters(editedFilters.concat([data.id]))
    }
    setModified(true);
  }

  const deleteSelected = () => {
    var arr = deleted;
    if (arr.includes(selected.id)) {
      arr = arr.filter((f) => {
        return f != selected.id;
      });
    }
    else {
      arr.push(selected.id)
      setModified(true);
    }
    setDeleted(JSON.parse(JSON.stringify(arr)));
  }

  const handleFilterSelection = (filter) => {
    selectFilter(filter);
  }

  return (
    <Fragment>
      <button
        className={"btn btn-secondary"}
        onClick={handleClickOpen}
        style={{ float: 'right' }}
      >
        {I18n.t('manage_filters')}
      </button>
      <MuiDialog
        fullWidth={true}
        maxWidth={'lg'}
        onClose={handleClose}
        open={open}
      >
        {loading ? <LoadingIndicator /> :
          <div style={{ padding: "0 25px" }}>
            <h1>{I18n.t('manage_filters')}</h1>
            {response && <div className={"error"}>{JSON.stringify(response)}</div>}
            <div className="row">
              <div className="col-md-6 filter_container">
                <button
                  className={"btn btn-secondary plus"}
                  style={{ float: 'right', margin: "0px" }}
                  onClick={(e) => newFilter()}>
                  {I18n.t("new")}
                </button>
                <h2>{I18n.t("filter_list")}</h2>
                <FilterList
                  filters={filters}
                  selected={selected}
                  handleFilterSelection={handleFilterSelection}
                  selectFilter={selectFilter}
                  deleted={deleted}
                />
              </div>

              <div className="col-md-6 filter_container" style={{ overflowX: 'hidden' }}>
                <div className="row">
                  <div className={"col-12"} >
                    {selected && <button className={"pull-right"} onClick={() => deleteSelected()}>{deleted.includes(selected.id) ? I18n.t("restore") : I18n.t("T_delete")}</button>}
                    <h2 className="">{I18n.t("filter_details")}</h2>
                  </div>
                  {selected &&
                    <Fragment>
                      <div className="col-2">
                        <div>{I18n.t('name')}</div>
                      </div>
                      <div className="col-10">
                        <input value={selected.name} onChange={(e) => handleChange('info', 'name', e.target.value)} style={{ width: "100%" }} />
                      </div>
                      <div className="col-2">
                        <div>{I18n.t('description')}</div>
                      </div>
                      <div className="col-10">
                        <textarea rows="2" value={selected.description || ''} onChange={(e) => handleChange('info', 'description', e.target.value)} style={{ width: "100%" }} />
                      </div>

                      {Object.keys(options).map((category) => (
                        <Fragment key={`category_${category}`}>
                          {selected.filters[category] &&
                            <Fragment>
                              <hr className="col-12" style={{ margin: "15px 0", border: "solid 1px lightgray" }} />
                              <h3 className="col-6">{I18n.t(category)}</h3>
                              {selected.filters[category].filters.length > 1 &&
                                <Fragment>
                                  <div className="col-2">
                                    <div className="bold">{I18n.t('operator')}</div>
                                  </div>
                                  <div className="col-2">
                                    <Select value={selected.filters[category].operator} onChange={(e) => handleChange(category, 'operator', e.target.value)} >
                                      {operators.map(option => (
                                        <MenuItem key={`operator_${option}`} value={option}>
                                          {I18n.t(option)}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </div>
                                </Fragment>
                              }
                              {
                                selected.filters[category].filters.map((filter, index) => (
                                  <FilterValueInput
                                    key={`${selected.id}_filter_${index}`}
                                    filter={filter}
                                    index={index}
                                    handleChange={(category, type, data) => handleChange(category, type, data)}
                                    options={options}
                                    category={category}
                                  />
                                ))
                              }
                              < div className="col-2">
                                {selected.filters[category].filters.length < 8 &&
                                  <button className={"btn btn-secondary"} onClick={(e) => handleChange(category, 'add', false, undefined)} style={{ borderRadius: "5px" }} >+</button>
                                }
                                {selected.filters[category].filters.length > 0 &&
                                  <button className={"btn btn-secondary"} onClick={(e) => handleChange(category, 'remove', false, undefined)} style={{ borderRadius: "5px" }} >-</button>
                                }
                              </div>
                            </Fragment>}
                        </Fragment>
                      ))}

                    </Fragment>
                  }
                </div>
              </div>
            </div>

            <div>
              <DialogActions>
                <button onClick={handleClose} className="btn btn-default xl">
                  {props.cancelLabel || I18n.t("cancel").toUpperCase()}
                </button>
                <button
                  onClick={handleSave}
                  className="btn btn-primary xl"
                  disabled={loading}
                >
                  {props.saveLabel || I18n.t("save").toUpperCase()}
                </button>
              </DialogActions>
            </div>
          </div>
        }
      </MuiDialog >
    </Fragment >
  );
}

function FilterList({ filters, handleFilterSelection, selectFilter, selected, deleted }) {
  return (
    <>
      {filters.map((filter, index) => (
        <div className={"row"} key={`filter${index}`} style={{ display: 'block' }} >
          <Button
            className={"col-12 overflow_ellipsis" + (selected && selected.id == filter.id ? " selected" : "")}
            style={{ height: "25px", borderRadius: "5px", justifyContent: 'left', color: (deleted.includes(filter.id) ? "red" : "black"), backgroundColor: (selected && selected.id == filter.id ? "#79b711" : "white") }}
            onClick={(e) => selectFilter(filter, index)}
          >
            <Icon style={{ marginRight: "3px" }}>{deleted.includes(filter.id) ? 'clear' : ''}</Icon>
            {filter.name}
          </Button>
        </div>
      ))
      }
    </>
  )
}

function FilterValueInput({ filter, index, handleChange, options, category }) {
  var context = options[category].find(o => o.value == filter.value);

  const inputType = (type) => {
    if (type == 'select') {
      return (<Select
        value={filter.detail}
        onChange={(e) => handleChange(category, 'detail', { value: e.target.value, index: index })}
        style={{ width: "100%" }}
      >
        {(context.options || []).map((option) => (
          <MenuItem key={`value_${option.key}_${option.value}`} value={option.value}>
            { context.translate ? I18n.t(option.key) : option.key}
          </MenuItem>
        ))}
      </Select>)
    }
    if (type == 'autocomplete') {
      return (<Autocomplete
        key={`autocomplete_${category}_${index}`}
        value={filter.detail}
        options={context.options}
        onChange={(value) => handleChange(category, 'detail', { value: value, index: index })}
      />)
    }
    if (type == 'range') {
      return (
        <div style={{ display: 'flex' }}>
          <div style={{ margin: 'auto', paddingRight: '5px' }}>{'min '}</div>
          <TextField
            style={{ width: "50%" }}
            value={filter.detail.min || ''}
            onChange={(e) => handleChange(category, 'detail', { value: { min: e.target.value, max: filter.detail.max }, index: index })}
          />
          <div style={{ margin: 'auto', paddingRight: '5px' }}>{'max '}</div>
          <TextField
            style={{ width: "50%" }}
            value={filter.detail.max || ''}
            onChange={(e) => handleChange(category, 'detail', { value: { min: filter.detail.min, max: e.target.value }, index: index })}
          />
        </div>
      )
    }
    return (<input
      style={{ width: "100%" }}
      value={filter.detail}
      onChange={(e) => handleChange(category, 'detail', { value: e.target.value, index: index })}
    />)
  }

  return (
    <div key={`filter_input_${index}_${category}`} className="col-10">
      <div className="row">
        <div className='col-5'>
          <Select
            value={filter.value}
            onChange={(e) => handleChange(category, 'value', { value: e.target.value, index: index })}
            style={{ width: "100%" }}
          >
            {options[category].map((option) => (
              <MenuItem key={`value_${option.value}_${option.id}`} value={option.value}>
                {option.translate ? I18n.t(option.value) : option.value}
              </MenuItem>
            ))}
          </Select>
        </div>
        {options[category].find(o => o.value == filter.value) != undefined && options[category].find(o => o.value == filter.value).type != null &&
          <div className='col-5'>
            {inputType(options[category].find(o => o.value == filter.value).type)}
          </div>
        }
      </div>
    </div>
  )
}