import React, { useEffect } from "react";
import {
  Button,
  Checkbox,
  Select,
  MenuItem,
  Icon,
  Tooltip,
  TextField,
} from "@material-ui/core/";
import Options from "@views/questionnaire/options";

export default React.memo(function Question(props) {
  function renderComponentOptions() {
    return Object.keys(props.components).map((key) => {
      return (
        <MenuItem value={props.components[key].value} key={key}>
          {props.components[key].label}
        </MenuItem>
      );
    });
  }

  return (
    <div
      className="edit_box"
      style={{ marginTop: props.question.sort_order == 1 ? 50 : 0 }}
    >
      <div>
        <h2 style={{ display: "inline" }}>
          {`Page ${props.question.questionnaire_page} Question ${props.question.sort_order}`}
          <Tooltip title="Delete">
            <Button
              className="edit_remove"
              onClick={(e) =>
                props.handleQuestion(
                  "remove",
                  props.question.questionnaire_page,
                  props.question.sort_order
                )
              }
            >
              <Icon>delete</Icon>
            </Button>
          </Tooltip>
          <Tooltip title="New question">
            <Button
              className="edit_remove"
              onClick={(e) =>
                props.handleQuestion(
                  "add",
                  props.question.questionnaire_page,
                  props.question.sort_order
                )
              }
            >
              <Icon>addBox</Icon>
            </Button>
          </Tooltip>
          {props.question.sort_order > 1 && (
            <Tooltip title="Page break">
              <Button
                className="edit_remove"
                onClick={(e) => props.handlePage(props.question, "add")}
              >
                <Icon>bookmark</Icon>
              </Button>
            </Tooltip>
          )}
          {props.question.sort_order > 1 && (
            <Tooltip title="Move up">
              <Button
                className="edit_remove"
                onClick={(e) => {
                  props.handleQuestion(
                    "up",
                    props.question.questionnaire_page,
                    props.question.sort_order
                  );
                }}
              >
                <Icon>arrowDropUp</Icon>
              </Button>
            </Tooltip>
          )}
          <Tooltip title="Move down">
            <Button
              className="edit_remove"
              onClick={(e) => {
                props.handleQuestion(
                  "down",
                  props.question.questionnaire_page,
                  props.question.sort_order
                );
              }}
            >
              <Icon>arrowDropDown</Icon>
            </Button>
          </Tooltip>
        </h2>
      </div>
      <TextField
        label={"str_id"}
        value={props.question.str_id || ""}
        onChange={(e) =>
          props.handleQuestionValue(props.index, "str_id", e.target.value)
        }
      />
      <TextField
        label={"translation key"}
        value={props.question.tkey || ""}
        onChange={(e) =>
          props.handleQuestionValue(props.index, "tkey", e.target.value)
        }
        style={{ marginRight: 20 }}
      />
      <Select
        label={"type"}
        value={props.components[props.question.type].value}
        style={{ width: "200px", marginRight: 20 }}
        onChange={(e) =>
          props.handleQuestionValue(props.index, "type", e.target.value)
        }
      >
        {renderComponentOptions()}
      </Select>
      <TextField
        label={"dependancy"}
        value={props.question.dependancy}
        onChange={(e) =>
          props.handleQuestionValue(props.index, "dependancy", e.target.value)
        }
        style={{ marginRight: 20 }}
      />
      {"Mandatory"}
      <Checkbox
        checked={props.question.mandatory || false}
        onChange={(e) =>
          props.handleQuestionValue(
            props.index,
            "mandatory",
            !props.question.mandatory
          )
        }
      />
      {"Color report"}
      <Checkbox
        checked={props.question.color_report || false}
        onChange={(e) =>
          props.handleQuestionValue(
            props.index,
            "color_report",
            !props.question.color_report
          )
        }
      />
      <div className="translatio_box">{`${I18n.t(props.question.tkey)}`}</div>
      <Options
        question={props.question}
        options={props.question.options}
        handleOptions={props.handleOptions}
        handleOptionValue={props.handleOptionValue}
        questionIndex={props.index}
      />
    </div>
  );
});
