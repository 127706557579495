import React, { useEffect, Fragment } from "react";
import MuiDialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { withStyles } from "@material-ui/core/styles";
import LoadingIndicator from "@components/loading_indicator";
import EmployeeImport from "@views/employee/import";

Dialog.defaultProps = {
  maxWidth: "md", // Maximum width of the dialog ['xs', 'sm', 'md', 'lg', 'xl', false]
  fullWidth: false, // Set to true to dialog always have max width
  dialogBtnClass: "btn btn-primary", // Class for the button that opens the dialog
  dialogBtnText: "", // Label on the button that opens the dialog
  dialogTitle: "", // Title of dialog
  content: null, // React node, will be replaced by contentHtml/SSR html if contentUrl defined
  contentHtml: "", // HTML content, will be replaces by SSR html if contentUrl defined
  contentUrl: "", // Url to server side rendered html.
  submitPath: null, // If the content contains a form, where we should post it. Give null to use the action attribute.
};

const styles = (theme) => ({
  root: {
    padding: "1rem",
    minWidth: "400px",
    maxWidth: "80%",
  },
});

function Dialog(props) {
  const [open, setOpen] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [loading, setLoading] = React.useState(props.contentUrl.length > 0);
  const [html, setHtml] = React.useState(props.contentHtml);
  const isFirstRun = React.useRef(true);
  const dialog = React.useRef(null);

  const handleClickOpen = (e) => {
    if (props.onClick) {
      props.onClick();
    }
    e.stopPropagation();
    setOpen(true);
  };
  const handleClose = (e) => {
    e.stopPropagation();
    setOpen(false);
  };
  const handleSave = (e) => {
    e.stopPropagation();

    const submit = async () => {
      setSaving(true);
      var form = dialog.current.querySelector("form");
      var formData = new FormData(form);
      formData.append("no_layout", true);
      const response = await fetch(
        props.submitPath ||
        dialog.current.querySelector("form").getAttribute("action"),
        {
          method: props.submitMethod || "POST",
          body: formData,
        }
      );
      setSaving(false);
      if (response.status === 422) {
        const html = await response.text();
        setHtml(html);
      } else if (response.redirected) {
        setOpen(false);
        Turbolinks.clearCache();
        Turbolinks.visit(response.url);
      } else {
        Turbolinks.clearCache();
        setOpen(false);
        if (props.afterSave && typeof props.afterSave === "function") {
          props.afterSave();
        }
      }
    };

    submit();
  };

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    const load = async () => {
      setLoading(true);
      var symbol = props.contentUrl.indexOf('?') >= 0 ? '&' : '?'
      const response = await fetch(props.contentUrl + symbol + "no_layout=true");
      const html = await response.text();
      setHtml(html);
      setLoading(false);
    };

    if (open && props.contentUrl) load();
  }, [open]);

  const click = (e) => {
    e.stopPropagation();
  };

  return (
    <Fragment>
      <button
        color="secondary"
        onClick={handleClickOpen}
        className={props.dialogBtnClass}
      >
        {props.dialogBtnText}
      </button>
      <MuiDialog
        maxWidth={props.maxWidth}
        fullWidth={props.fullWidth}
        onClose={handleClose}
        open={open}
        PaperProps={{ classes: { root: props.classes.root } }}
        style={{ margin: 'auto' }}
      >
        <h1>{props.dialogTitle}</h1>

        {props.content ? (
          props.content(() => {
            setOpen(false);
          })
        ) : (
            <div>
              {loading ? (
                <LoadingIndicator />
              ) : (
                  <DialogContent
                    ref={dialog}
                    dangerouslySetInnerHTML={{ __html: html }}
                    onClick={click}
                    errormessage={props.error_message}
                    props={props}
                  />
                )}
              <DialogActions>
                <button onClick={handleClose} className="btn btn-default xl">
                  {props.cancelLabel || I18n.t("cancel").toUpperCase()}
                </button>
                <button
                  onClick={handleSave}
                  className="btn btn-primary xl"
                  disabled={saving}
                >
                  {props.saveLabel || I18n.t("save").toUpperCase()}
                </button>
              </DialogActions>
            </div>
          )}
      </MuiDialog>
    </Fragment>
  );
}

export default withStyles(styles, { name: "MuiDialog" })(Dialog);
