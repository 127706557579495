import React, { useEffect } from 'react';

export default function Base(props){
  const {activeConditions, str_id, condition, conditional, children, className} = props;
  const [visible, setVisible] = React.useState(true);
  
  useEffect(()=> {
    if (condition && conditional && activeConditions)
      setVisible(activeConditions[conditional] == condition);
  }, [activeConditions]); // Others doesn't change

  return (
    <div style={{display: visible ? 'block' : 'none'}} className={className}>
      {visible ? children : null}
    </div>
  )
}